import { Popover } from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import { Box } from "@mui/system";
import { selectMediaById } from "../viewerSlice";
import { IMedia } from "../../studies/studiesSlice";
import { useGetPatientStudiesByEncounterQuery } from "../../studies/dataAccess";
import { useParams } from "react-router-dom";
import React from "react";
import LateralityPill from "../../studies/StudyCard/LateralityPill";
import InfoBox from "../../studies/StudyCard/InfoBox";
import DisplayText from "../../../components/DisplayText/DisplayText";

interface IMediaViewerHeaderProps {
    mediaId: string;
}

const CompareMediaViewerHeader: React.FC<IMediaViewerHeaderProps> = ({ mediaId }) => {
    const { patientId } = useParams<{ patientId: string }>();

    const { data: studiesByEncounter } = useGetPatientStudiesByEncounterQuery({ patientId });

    const media = selectMediaById(studiesByEncounter, mediaId) as IMedia;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    bgcolor: "#0e1118",
                    flex: 1,
                    px: 20,
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                {media.laterality ? <LateralityPill laterality={media.laterality} /> : null}
                <Box>
                    {media.fileName ? <DisplayText type="bodyMedium" variant="bold" text={media.fileName} /> : null}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                        sx={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                        }}
                        onClick={handlePopoverOpen}
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                    >
                        <InfoIcon fontSize="small" />
                    </Box>
                    <Popover
                        id="mouse-over-popover"
                        sx={{
                            pointerEvents: "none",
                        }}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        onClose={handlePopoverClose}
                        disablePortal
                        disableRestoreFocus
                    >
                        <InfoBox media={media} />
                    </Popover>
                </Box>
            </Box>
        </>
    );
};

export default CompareMediaViewerHeader;
