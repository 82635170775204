import { AuthService } from "./nebula.service";
import { nebulaClient, nebulaClient2 } from "../../lib/nebulaClient";
import { ILoginInfo } from "./login/loginSlice";

interface UserDTO {
    userID: string;
    loginInfo: ILoginInfo;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    avatarURL: string;
    activated: boolean;
    adminFeatures: string;
    accessDate: string;
    accessExpires: string;
    accessOK: boolean;
    orgUuid: string;
    _activeOrgUuid: string;
    topOrgUuid: string;
    activeOrgUuid: string;
    _projectOrgUuid: string;
    consentUuid: string;
}
export const authService: AuthService = {
    getUserData: async () => {
        const response = await nebulaClient.get<UserDTO>("/user/me");

        return response;
    },
    // @ts-ignore
    login: async (credentials) => {
        const response = await nebulaClient.post<string>("/token/login", credentials, {
            withAuth: false,
            headers: {
                "Content-Type": "application/json",
            },
        });

        const token = response.meta?.headers?.["x-auth-token"];

        if (token) {
            return { data: token };
        }

        if (response.error?.kind === "ApiError") {
            return {
                error: {
                    ...response.error,
                    // @ts-ignore
                    message: response.error.message?.message,
                },
            };
        }

        return response;
    },
    recoverPassword: async (email: string) => {
        const response = await nebulaClient.post<void>(
            "/password/recover",
            { email },
            {
                withAuth: false,
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: false,
            }
        );

        return response;
    },
    validateToken: async (token: string) => {
        const response = await nebulaClient.get<void>(`/password/recover/confirm/${token}`, {
            withAuth: false,
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response;
    },
    resetPassword: async (token: string, newPassword: string) => {
        const response = await nebulaClient.post<void>(
            `password/recover/confirm/${token}`,
            { password: newPassword },
            {
                withAuth: false,
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: false,
            }
        );

        return response;
    },
    updateCurrentGroup: async (trialId: string) => {
        const response = await nebulaClient.post<void>(`/org/assign/my/active`, { uuid: trialId });

        return response;
    },
    checkIfTrialHasManual: async (trialId: string) => {
        return await nebulaClient2.get<any>(`trial/${trialId}/check-manual`);
    },
};
