import React, { useCallback, useMemo, useEffect, useState } from "react";
import PivotTableChartIcon from "@mui/icons-material/PivotTableChart";
import {
    DataGrid,
    GridCellParams,
    GridPreProcessEditCellProps,
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridValueFormatterParams,
} from "@mui/x-data-grid";
import { useGetUserDataQuery } from "../auth/dataAccess";
import { Box, Button, Checkbox, Tooltip } from "@mui/material";
import { IPatient } from "../patients/patientsSlice";
import {
    CrfField,
    CrfFieldOptionDefaultType,
    CrfFieldType,
    CrfReading,
    CrfReadingValue,
    FieldOption,
    Laterality,
    OptionType,
} from "./nebula.service";
import { EditableOptions } from "./components/EditableOptions";
import { TextAreaEditCell } from "./components/TextAreaEditCell";
import { ColumnHeader } from "./components/ColumnHeader";
import { EditableGridCell, GridCell } from "./components/GridCell";
import { useAppSelector } from "../../app/hooks";
import { v4 as uuidv4 } from "uuid";
import { getSelectedTrial } from "../auth/login/loginSlice";
import useRoles from "../permissions/useRoles";
import DisplayText from "../../components/DisplayText/DisplayText";
import { useEditCRFMutation, useSetFinalMutation, useUnsetFinalMutation } from "../crf/dataAccess";
import { useSnackbar } from "notistack";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { DatePickerEditCell } from "./components/DatePickerEditCell";

// Define constants for column widths
const COLUMN_WIDTHS = {
    final: 75,
    user: 200,
    laterality: 80,
};

// Custom toolbar component with Transpose button
const getCustomToolbar =
    ({ isTransposed, onTranspose }: { isTransposed: boolean; onTranspose: () => void }) =>
    () => {
        return (
            <GridToolbarContainer>
                {/* Standard DataGrid toolbar buttons */}
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                {/* Custom Transpose button */}
                <Button
                    onClick={onTranspose}
                    color="primary"
                    size="small"
                    sx={{ ml: 1 }}
                    startIcon={<PivotTableChartIcon />}
                >
                    {isTransposed ? "Revert Transpose" : "Transpose Table"}
                </Button>
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    };

interface ICRFVisitTableProps {
    fields: CrfField[];
    data: CrfReading[];
    patient: IPatient;
    visit: {
        name: string;
        uuid: string;
    };
}

const baseStyles = {
    "height": 250,
    "width": "100%",
    "padding": 2,
    "& .nonEditable": {
        backgroundColor: "#29323f",
        color: "#9c9b9b",
    },
};

const getPositionStyles = (
    userRoles: { isProjectManager: boolean; isSponsor: boolean },
    hasMultipleRowsPerLaterality: boolean
) => {
    const { isProjectManager, isSponsor } = userRoles;
    const showFinalColumn = isProjectManager && hasMultipleRowsPerLaterality;

    return {
        "& .firstCell": {
            position: "sticky",
            left: showFinalColumn ? COLUMN_WIDTHS.final : 0,
            zIndex: 2,
            borderRight: isSponsor ? "2px solid #515151" : "none",
        },
        ...(!isSponsor && {
            "& .secondCell": {
                position: "sticky",
                left: showFinalColumn ? COLUMN_WIDTHS.user + COLUMN_WIDTHS.final : COLUMN_WIDTHS.user,
                zIndex: 2,
                borderRight: "2px solid #515151",
            },
            "& .finalCell": {
                position: "sticky",
                left: 0,
                zIndex: 2,
                borderRight: "2px solid #515151",
            },
        }),
    };
};

const transposedStyles = {
    "& .MuiDataGrid-columnHeaders": {
        "& .MuiDataGrid-columnHeadersInner": {
            "transform": "none !important",
            "& > div:first-child": {
                zIndex: 2,
                backgroundColor: "#202735",
            },
        },
    },
};

const getDataGridHeaderStyles = (
    userRoles: { isProjectManager: boolean; isSponsor: boolean },
    hasMultipleRowsPerLaterality: boolean
) => {
    const { isProjectManager, isSponsor } = userRoles;
    const showFinalColumn = isProjectManager && hasMultipleRowsPerLaterality;

    return {
        "& .MuiDataGrid-cell--editing": {
            position: "relative",
            overflow: "visible !important",
        },
        "& .MuiDataGrid-columnHeaders": {
            "& .MuiDataGrid-columnHeadersInner": {
                "transform": "none !important",
                "& > div:first-child": {
                    zIndex: 2,
                    backgroundColor: "#202735",
                },
                ...(!isSponsor && {
                    "& > div:nth-child(2)": {
                        zIndex: 2,
                        backgroundColor: "#202735",
                    },
                }),
                ...(showFinalColumn && {
                    "& > div:nth-child(3)": {
                        zIndex: 2,
                        backgroundColor: "#202735",
                    },
                }),
            },
        },
    };
};

const DisabledFinalCheckbox = () => (
    <Tooltip title="Final marked as default since this is the only reading for this laterality">
        <span>
            <Checkbox
                checked={true}
                disabled={true}
                icon={<CheckBoxIcon />}
                checkedIcon={<CheckBoxIcon />}
                sx={{
                    "color": "grey.500",
                    "&.Mui-disabled": {
                        color: "grey.500",
                    },
                    "opacity": 0.7,
                }}
            />
        </span>
    </Tooltip>
);

// Add this new component near the top of the file with other components
const TwoLineHeader = ({ email, laterality }: { email: string; laterality: string }) => (
    <Box sx={{ textAlign: "left" }}>
        <DisplayText type="bodySmall" text={email} variant="semiBold" />
        <DisplayText type="bodySmall" style={{ marginBottom: "4px" }} text={`${laterality.toUpperCase()}`} />
    </Box>
);

const CRFVisitTable: React.FC<ICRFVisitTableProps> = ({ fields, data, patient, visit }) => {
    const { userHasRole } = useRoles();
    const { enqueueSnackbar } = useSnackbar();
    const tableUniqueId = useMemo(() => `tableId-${uuidv4()}`, []);

    const selectedTrial = useAppSelector(getSelectedTrial);
    const { data: userData } = useGetUserDataQuery();

    const [unsetFinal] = useUnsetFinalMutation();
    const [setFinal] = useSetFinalMutation();
    const [editCRF] = useEditCRFMutation();

    // State to manage transpose
    const [isTransposed, setIsTransposed] = useState(false);

    const renderEditCell =
        (field: CrfField, laterality?: string, email?: string) => (params: GridRenderEditCellParams) => {
            // Assign laterality from row if not provided
            laterality = laterality || (params.row.laterality as string);
            // Assign email from row if not provided
            const userEmail = email || (params.row.userEmail as string);

            if (field.fieldType === CrfFieldType.date) {
                const handleUpdate = (newValue: any) => {
                    const payload = {
                        laterality: laterality as Laterality,
                        visitId: visit.uuid,
                        email: userEmail,
                        data: [
                            {
                                uuid: field.uuid,
                                fieldName: field.fieldName,
                                fieldValue: newValue,
                            },
                        ],
                    };

                    return editCRF({ patientId: patient.uuid, crfUpdate: payload }).unwrap();
                };

                return (
                    <DatePickerEditCell
                        {...params}
                        email={userEmail}
                        visitId={visit.uuid}
                        patientId={patient.uuid}
                        laterality={laterality}
                        handleUpdate={handleUpdate}
                    />
                );
            } else if (field.fieldType === CrfFieldType.string) {
                const handleUpdate = (newValue: any) => {
                    const payload = {
                        laterality: laterality as Laterality,
                        visitId: visit.uuid,
                        email: userEmail,
                        data: [
                            {
                                uuid: field.uuid,
                                fieldName: field.fieldName,
                                fieldValue: newValue,
                            },
                        ],
                    };

                    return editCRF({ patientId: patient.uuid, crfUpdate: payload }).unwrap();
                };

                return (
                    <TextAreaEditCell
                        {...params}
                        email={userEmail}
                        visitId={visit.uuid}
                        patientId={patient.uuid}
                        fieldDefinition={field}
                        laterality={laterality}
                        handleUpdate={handleUpdate}
                    />
                );
            } else if (field.fieldType === CrfFieldType.list) {
                return (
                    <EditableOptions
                        {...params}
                        options={field.fieldOptions as FieldOption[]}
                        fieldDefinition={field}
                        laterality={laterality}
                        visitId={visit.uuid}
                        patientId={patient.uuid}
                        userEmail={userEmail}
                    />
                );
            }
        };

    const renderCell =
        (field: CrfField, laterality?: string, email?: string) =>
        (params: GridRenderCellParams<string, any, string>) => {
            // Assign laterality from row if not provided
            const lat = laterality || (params.row.laterality as string);
            // Assign email from row if not provided
            const userEmail = email || (params.row.userEmail as string);
            return (
                <EditableGridCell
                    {...params}
                    email={userEmail}
                    visitId={visit.uuid}
                    patientId={patient.uuid}
                    fieldDefinition={field}
                    laterality={lat}
                />
            );
        };

    const validateFixedOptions = (newValue: any, options: FieldOption[]) => {
        const fixedOptionsValues = options.filter(
            (option): option is CrfFieldOptionDefaultType => option.type !== OptionType.input
        );

        const optionValues = fixedOptionsValues.map((option) => option.value);

        return optionValues.includes(newValue);
    };

    const getFieldValidator = (field: CrfField) => (params: GridPreProcessEditCellProps) => {
        // Avoid showing error tooltip when user didn't type anything yet
        if (params.props.value === "") return { ...params };

        if (field.fieldType === CrfFieldType.date) {
            const isDate = new Date(params.props.value);
            return isDate ? { ...params } : { ...params, error: "Value must be a date." };
        }

        if (field.fieldType === CrfFieldType.list) {
            if (validateFixedOptions(params.props.value, field.fieldOptions as FieldOption[])) return { ...params };
        }

        if (field.fieldType === CrfFieldType.string) {
            const isValidString = params.props.value.length > 0;
            return isValidString ? { ...params } : { ...params, error: "Value must be a string." };
        }

        const isPositiveFloat = Number(params.props.value) >= 0;
        const error = isPositiveFloat === true ? null : "Value must be positive decimal.";

        return { ...params.props, error };
    };

    const getFieldFormatter = (field: CrfField) => (params: GridValueFormatterParams<string>) => {
        if (field.fieldType === CrfFieldType.date) {
            if (!params.value) return "";
            return new Date(params.value).toLocaleDateString("en-US", { timeZone: "UTC" });
        }
        if (field.fieldType !== CrfFieldType.list) return params.value;

        const options = field.fieldOptions;

        const selectableOptions = options.filter(
            (option: FieldOption): option is CrfFieldOptionDefaultType => option.type !== OptionType.input
        );

        const selectedOption = selectableOptions.find((option) => option.value === params.value);

        if (selectedOption) {
            return selectedOption.label;
        }

        if (field.precision !== undefined) {
            const isNumber = parseFloat(params.value);
            return isNumber ? parseFloat(params.value).toFixed(field.precision) : params.value;
        }

        return params.value;
    };

    // Adjust column width based on content
    const getColumnWidth = (field: CrfField) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        if (context) {
            context.font = "16px Roboto";
            // Add 20px padding for cell content and 35px for laterality (OU) | (OD) | (OS)
            return Math.ceil(context.measureText(field.fieldName).width) + 20 + 35;
        }
    };

    // Memoized columns based on transpose state
    const displayColumns = useMemo(() => {
        // Check if there are multiple rows per laterality
        const hasMultipleRowsPerLaterality = data.some((reading) => {
            const sameLatRows = data.filter((r) => r.laterality === reading.laterality);
            return sameLatRows.length > 1;
        });

        if (!isTransposed) {
            // Original Columns
            const fixedColumns = [
                ...(userHasRole("PROJECT_MANAGER") && hasMultipleRowsPerLaterality
                    ? [
                          {
                              field: "final",
                              headerName: "Final",
                              width: COLUMN_WIDTHS.final,
                              hideable: false,
                              editable: true,
                              type: "boolean",
                              renderCell: (params: GridRenderCellParams) => {
                                  // Get all readings for this laterality
                                  const readingsForLaterality = data.filter(
                                      (reading) => reading.laterality === params.row.laterality
                                  );
                                  const hasMultipleReadingsForThisLaterality = readingsForLaterality.length > 1;

                                  // If only one reading for this laterality, show checked and disabled checkbox
                                  if (!hasMultipleReadingsForThisLaterality) {
                                      return <DisabledFinalCheckbox />;
                                  }
                                  const handleFinalChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
                                      event.stopPropagation();

                                      try {
                                          await setFinal({
                                              patientId: patient.uuid,
                                              finalRequest: {
                                                  visitId: visit.uuid,
                                                  laterality: params.row.laterality,
                                                  userId: params.row.userUuid,
                                              },
                                          }).unwrap();
                                      } catch (error) {
                                          enqueueSnackbar("Failed to update final status", {
                                              variant: "error",
                                          });
                                      }
                                  };

                                  const handleUnsetFinal = async (event: React.ChangeEvent<HTMLInputElement>) => {
                                      event.stopPropagation();

                                      try {
                                          await unsetFinal({
                                              patientId: patient.uuid,
                                              finalRequest: {
                                                  visitId: visit.uuid,
                                                  laterality: params.row.laterality,
                                                  userId: params.row.userUuid,
                                              },
                                          }).unwrap();
                                      } catch (error) {
                                          enqueueSnackbar("Failed to update final status", {
                                              variant: "error",
                                          });
                                      }
                                  };

                                  if (params.row.final) {
                                      return (
                                          <Checkbox
                                              checked={true}
                                              onChange={handleUnsetFinal}
                                              onClick={(e) => e.stopPropagation()}
                                              icon={<CheckBoxIcon color="success" />}
                                              checkedIcon={<CheckBoxIcon color="success" />}
                                          />
                                      );
                                  }

                                  // Check if there's another row with same laterality marked as final
                                  const hasFinalForLaterality = data.some(
                                      (reading) =>
                                          reading.laterality === params.row.laterality &&
                                          reading.final &&
                                          reading.user.uuid !== params.row.userUuid
                                  );

                                  if (hasFinalForLaterality) {
                                      return (
                                          <Tooltip title="To mark this reading as final, first unmark the current final reading for this laterality">
                                              <span>
                                                  <CheckBoxOutlineBlankIcon color="disabled" />
                                              </span>
                                          </Tooltip>
                                      );
                                  }

                                  return (
                                      <Checkbox
                                          checked={false}
                                          onChange={handleFinalChange}
                                          onClick={(e) => e.stopPropagation()}
                                      />
                                  );
                              },
                              sortable: false,
                          },
                      ]
                    : []),
                ...(!userHasRole("SPONSOR")
                    ? [
                          {
                              field: "userEmail",
                              headerName: "User",
                              hideable: false,
                              width: COLUMN_WIDTHS.user,
                              renderCell: (params: GridRenderCellParams<string>) => <GridCell {...params} />,
                          },
                      ]
                    : []),
                {
                    field: "laterality",
                    headerName: "Laterality",
                    width: COLUMN_WIDTHS.laterality,
                    hideable: false,
                    valueFormatter: (params: GridValueFormatterParams<string>) => params.value.toUpperCase(),
                    sortable: false,
                },
                {
                    field: "notes",
                    headerName: "Notes",
                    width: 140,
                    renderHeader: () => (
                        <DisplayText
                            style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}
                            type="bodySmall"
                            text="Notes"
                        />
                    ),
                    editable: true,
                    renderCell: (params: GridRenderCellParams<string>) => {
                        const email = params.row.userEmail;
                        const laterality = params.row.laterality;
                        return (
                            <EditableGridCell
                                {...params}
                                email={email}
                                visitId={visit.uuid}
                                patientId={patient.uuid}
                                laterality={laterality}
                            />
                        );
                    },
                    renderEditCell: (props: GridRenderEditCellParams) => {
                        const email = props.row.userEmail;
                        const laterality = props.row.laterality;

                        const handleUpdate = (newValue: any) => {
                            const payload = {
                                laterality,
                                visitId: visit.uuid,
                                email,
                                notes: newValue,
                            };

                            return editCRF({ patientId: patient.uuid, crfUpdate: payload }).unwrap();
                        };

                        return (
                            <TextAreaEditCell
                                patientId={patient.uuid}
                                {...props}
                                email={email}
                                visitId={visit.uuid}
                                laterality={laterality}
                                handleUpdate={handleUpdate}
                            />
                        );
                    },
                    sortable: false,
                },
            ];

            const metadataColumns = [
                {
                    field: "trialName",
                    headerName: "Trial",
                    renderCell: (params: GridRenderCellParams<string>) => <GridCell {...params} />,
                    hide: true,
                    hideable: false,
                },
                {
                    field: "visitName",
                    headerName: "Visit",
                    renderCell: (params: GridRenderCellParams<string>) => <GridCell {...params} />,
                    hide: true,
                    hideable: false,
                },
                {
                    field: "patientName",
                    headerName: "Patient",
                    renderCell: (params: GridRenderCellParams<string>) => <GridCell {...params} />,
                    hide: true,
                    hideable: false,
                },
                {
                    field: "siteName",
                    headerName: "Site",
                    renderCell: (params: GridRenderCellParams<string>) => <GridCell {...params} />,
                    hide: true,
                    hideable: false,
                },
            ];

            const fieldColumns = fields.map((field) => ({
                field: field.fieldName,
                headerName: field.fieldName,
                minWidth: getColumnWidth(field),
                flex: 1,
                renderHeader: () => <ColumnHeader field={field} />,
                valueFormatter: getFieldFormatter(field),
                hide: !field.isRequired,
                hideable: true,
                editable: true,
                preProcessEditCellProps: getFieldValidator(field),
                renderEditCell: renderEditCell(field),
                renderCell: renderCell(field),
                sortable: false,
            }));

            return [...fixedColumns, ...fieldColumns, ...metadataColumns];
        } else {
            const baseColumns = [
                {
                    field: "attribute",
                    headerName: "Attribute",
                    width: 200,
                    sortable: false,
                    pinned: "left",
                },
            ];

            const dynamicColumns = data.map((crfReading) => ({
                field: `${crfReading.user.uuid}-${crfReading.laterality}-${visit.name}`,
                headerName: `${crfReading.user.email} - (${crfReading.laterality})`,
                flex: 1,
                maxWidth: 300,
                sortable: false,
                editable: crfReading.isEditable,
                renderEditCell: (params: GridRenderEditCellParams) => {
                    const fieldName = params.id;
                    const field = fields.find((f) => f.fieldName === fieldName) as CrfField;
                    const laterality = crfReading.laterality;
                    const userEmail = crfReading.user.email;

                    if (params.id === "notes") {
                        const handleUpdate = (newValue: any) => {
                            const payload = {
                                laterality,
                                visitId: visit.uuid,
                                email: userEmail,
                                notes: newValue,
                            };

                            return editCRF({ patientId: patient.uuid, crfUpdate: payload }).unwrap();
                        };

                        return (
                            <TextAreaEditCell
                                {...params}
                                patientId={patient.uuid}
                                email={userEmail}
                                visitId={visit.uuid}
                                laterality={laterality}
                                handleUpdate={handleUpdate}
                            />
                        );
                    }

                    return renderEditCell(field, laterality, userEmail)(params);
                },
                renderCell: (params: GridRenderCellParams) => {
                    const fieldName = params.id;
                    const field = fields.find((f) => f.fieldName === fieldName) as CrfField;

                    if (fieldName === "final") {
                        // Get all readings for this laterality
                        const readingsForLaterality = data.filter(
                            (reading) => reading.laterality === crfReading.laterality
                        );
                        const hasMultipleReadingsForThisLaterality = readingsForLaterality.length > 1;

                        // If only one reading for this laterality, show checked and disabled checkbox
                        if (!hasMultipleReadingsForThisLaterality) {
                            return <DisabledFinalCheckbox />;
                        }
                        const handleFinalChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
                            event.stopPropagation();
                            try {
                                await setFinal({
                                    patientId: patient.uuid,
                                    finalRequest: {
                                        visitId: visit.uuid,
                                        laterality: crfReading.laterality,
                                        userId: crfReading.user.uuid,
                                    },
                                }).unwrap();
                            } catch (error) {
                                enqueueSnackbar("Failed to update final status", {
                                    variant: "error",
                                });
                            }
                        };

                        const handleUnsetFinal = async (event: React.ChangeEvent<HTMLInputElement>) => {
                            event.stopPropagation();
                            try {
                                await unsetFinal({
                                    patientId: patient.uuid,
                                    finalRequest: {
                                        visitId: visit.uuid,
                                        laterality: crfReading.laterality,
                                        userId: crfReading.user.uuid,
                                    },
                                }).unwrap();
                            } catch (error) {
                                enqueueSnackbar("Failed to update final status", {
                                    variant: "error",
                                });
                            }
                        };

                        if (params.row[params.field]) {
                            return (
                                <Checkbox
                                    checked={true}
                                    onChange={handleUnsetFinal}
                                    onClick={(e) => e.stopPropagation()}
                                    icon={<CheckBoxIcon color="success" />}
                                    checkedIcon={<CheckBoxIcon color="success" />}
                                />
                            );
                        }
                        // Check if there's another row with same laterality marked as final
                        const hasFinalForLaterality = data.some(
                            (reading) =>
                                reading.laterality === crfReading.laterality &&
                                reading.final &&
                                reading.user.uuid !== crfReading.user.uuid
                        );

                        if (hasFinalForLaterality) {
                            return (
                                <Tooltip title="To mark this reading as final, first unmark the current final reading for this laterality">
                                    <span>
                                        <CheckBoxOutlineBlankIcon color="disabled" />
                                    </span>
                                </Tooltip>
                            );
                        }

                        return (
                            <Checkbox
                                checked={false}
                                onChange={handleFinalChange}
                                onClick={(e) => e.stopPropagation()}
                            />
                        );
                    }

                    const laterality = crfReading.laterality;
                    const userEmail = crfReading.user.email;

                    return renderCell(field, laterality, userEmail)(params);
                },
                valueFormatter: (params: GridValueFormatterParams<string>) => {
                    const fieldName = params.id;

                    // Special handling for notes and final
                    if (fieldName === "notes") return params.value;
                    if (fieldName === "final") {
                        return params.value ? "Yes" : "No";
                    }

                    // Only look for field definition for regular fields
                    const field = fields.find((f) => f.fieldName === fieldName) as CrfField;
                    if (!field) return params.value;

                    return getFieldFormatter(field)(params);
                },
                renderHeader: () => <TwoLineHeader email={crfReading.user.email} laterality={crfReading.laterality} />,
            }));

            return [...baseColumns, ...dynamicColumns];
        }
    }, [fields, data, userHasRole, visit.uuid, isTransposed]);

    // Memoized rows based on transpose state
    const displayRows = useMemo(() => {
        if (!isTransposed) {
            // Original Rows
            return [...data]
                .sort((a, b) => a.user.email.localeCompare(b.user.email) || a.laterality.localeCompare(b.laterality))
                .map((crfReading: CrfReading) => ({
                    id: `${crfReading.user.uuid}-${crfReading.laterality}-${visit.name}`,
                    trialName: selectedTrial!.name,
                    visitName: visit.name,
                    siteName: patient.site,
                    patientName: patient.patientId,
                    isEditable: crfReading.isEditable,
                    laterality: crfReading.laterality,
                    notes: crfReading.notes,
                    patientId: patient.uuid,
                    visitId: visit.uuid,
                    userEmail: crfReading.user.email,
                    userUuid: crfReading.user.uuid,
                    final: crfReading.final,
                    // Dynamically add field values
                    // @ts-ignore
                    ...crfReading.values.reduce(
                        (acc: Record<string, any>, readingValue: CrfReadingValue) => ({
                            ...acc,
                            [readingValue.fieldName]: readingValue.fieldValue,
                        }),
                        {} as Record<string, any>
                    ),
                }));
        } else {
            const hasMultipleRowsPerLaterality = data.some((reading) => {
                const sameLatRows = data.filter((r) => r.laterality === reading.laterality);
                return sameLatRows.length > 1;
            });

            const analytesFields = fields
                .filter((field) => !["userEmail", "laterality"].includes(field.fieldName))
                .map((field) => field.fieldName);

            // Only include final in the fields if user is PM and has multiple rows per laterality
            const rowFields = [
                ...(userHasRole("PROJECT_MANAGER") && hasMultipleRowsPerLaterality ? ["final"] : []),
                "notes",
                ...analytesFields,
            ];

            const transposedRows = rowFields.map((fieldName) => ({
                id: fieldName,
                attribute:
                    fieldName === "notes"
                        ? fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
                        : fieldName === "final"
                        ? "Final"
                        : fieldName,
                isEditable: fieldName !== "final",
                ...data.reduce((acc, crfReading) => {
                    const key = `${crfReading.user.uuid}-${crfReading.laterality}-${visit.name}`;
                    let fieldValue;
                    if (fieldName === "notes") {
                        fieldValue = crfReading.notes;
                    } else if (fieldName === "final") {
                        fieldValue = crfReading.final;
                    } else {
                        fieldValue = crfReading.values.find((val) => val.fieldName === fieldName)?.fieldValue || "";
                    }
                    acc[key] = fieldValue;
                    return acc;
                }, {} as Record<string, any>),
            }));

            return transposedRows;
        }
    }, [patient, visit, selectedTrial, isTransposed, data, fields]);

    // Function to toggle transpose state
    const transposeTable = useCallback(() => {
        setIsTransposed((prev) => !prev);
    }, []);

    // Cell Class Name for styling
    const getCellClassName = useCallback(
        (params: GridCellParams) => {
            let className = "";
            if (userHasRole("SPONSOR")) {
                if (params.field === "laterality") {
                    className += " firstCell";
                }
            } else {
                if (params.field === "userEmail") {
                    className += " firstCell";
                }

                if (params.field === "laterality") {
                    className += " secondCell";
                }

                if (params.field === "final") {
                    className += " finalCell";
                }
            }

            if (params.isEditable) {
                className += " editableCell";
            } else {
                className += " nonEditable";
            }

            return className;
        },
        [userHasRole]
    );

    // Cell Editability
    const isCellEditable = useCallback((params: any) => {
        return params.row.isEditable;
    }, []);

    // Grid Features
    const features = { newEditingApi: true };

    const getFileName = () => {
        const userId = userData!.userID;
        const visitNumber = visit.name;
        const date = new Date().toJSON().slice(0, 10);

        return `${selectedTrial!.name}_${userId}_${visitNumber}_${date}`;
    };

    const fileName = getFileName();

    const csvOptions = {
        fileName,
        allColumns: true,
    };

    // Scroll Handling (Remains Unchanged)
    const handleScrollHorizontal = useCallback(() => {
        const virtualScrollerElement = document.querySelector(`#${tableUniqueId} .MuiDataGrid-virtualScroller`);
        if (virtualScrollerElement) {
            const currentScrollPos = virtualScrollerElement.scrollLeft;
            const columnsHeaders = document.querySelectorAll(
                `#${tableUniqueId} .MuiDataGrid-columnHeader:not([data-field="laterality"]):not([data-field="userEmail"]):not([data-field="attribute"]):not([data-field="final"])`
            );

            columnsHeaders.forEach((columnHeader: any) => {
                columnHeader.style.transform = `translate3d(-${currentScrollPos}px, 0px, 0px)`;
            });
        }
    }, [tableUniqueId]);

    useEffect(() => {
        const findVirtualScroller = () => {
            const virtualScrollerElement = document.querySelector(`#${tableUniqueId} .MuiDataGrid-virtualScroller`);
            if (!virtualScrollerElement) {
                setTimeout(findVirtualScroller, 100);
            } else {
                virtualScrollerElement.addEventListener("scroll", handleScrollHorizontal);
                return () => {
                    virtualScrollerElement.removeEventListener("scroll", handleScrollHorizontal);
                };
            }
        };
        findVirtualScroller();
    }, [tableUniqueId, handleScrollHorizontal, isTransposed]);

    // Move hasMultipleRowsPerLaterality check outside of useMemo to reuse it
    const hasMultipleRowsPerLaterality = useMemo(() => {
        return data.some((reading) => {
            const sameLatRows = data.filter((r) => r.laterality === reading.laterality);
            return sameLatRows.length > 1;
        });
    }, [data]);

    const userRoles = {
        isProjectManager: userHasRole("PROJECT_MANAGER"),
        isSponsor: userHasRole("SPONSOR"),
    };

    return (
        <Box
            sx={{
                ...baseStyles,
                ...getPositionStyles(userRoles, hasMultipleRowsPerLaterality),
                ...(isTransposed && transposedStyles),
            }}
            id={tableUniqueId}
        >
            <DataGrid
                key={isTransposed ? "transposed" : "original"}
                columns={displayColumns}
                sx={getDataGridHeaderStyles(userRoles, hasMultipleRowsPerLaterality)}
                disableVirtualization
                rows={displayRows}
                componentsProps={{ toolbar: { csvOptions, isTransposed, onTranspose: transposeTable } }}
                components={{
                    Toolbar: getCustomToolbar({ isTransposed, onTranspose: transposeTable }),
                }}
                getCellClassName={getCellClassName}
                isCellEditable={isCellEditable}
                experimentalFeatures={features}
                columnBuffer={displayColumns.length}
                density="compact"
                disableColumnFilter
                disableColumnMenu
                disableDensitySelector
                disableSelectionOnClick
                hideFooter
            />
        </Box>
    );
};

export default CRFVisitTable;
