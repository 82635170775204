import { Select, MenuItem, IconButton, SelectChangeEvent, Chip, Popover } from "@mui/material";
import {
    ContentCopy as ContentCopyIcon,
    Download as DownloadIcon,
    DeleteOutline as DeleteOutlineIcon,
    TableView as TableViewIcon,
    StickyNote2 as NoteIcon,
    Info as InfoIcon,
    Fullscreen as FullscreenIcon,
    FullscreenExit as FullscreenExitIcon,
} from "@mui/icons-material";
import CompareIcon from "@mui/icons-material/Compare";
import { Box } from "@mui/system";
import { selectMediaById, getMediaEncounter } from "./viewerSlice";
import { IEncounter, IMedia } from "../studies/studiesSlice";
import {
    useAddStudyCommentMutation,
    useDeleteStudyMutation,
    useDownloadStudyMutation,
    useGetPatientStudiesByEncounterQuery,
} from "../studies/dataAccess";
import { useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MenuList from "../../components/MenuList/MenuList";
import LateralityPill from "../studies/StudyCard/LateralityPill";
import CopyCaseInfo from "./CopyCaseInfo";

import { useGetPatientByIdQuery } from "../patients/dataAccess";
import usePermissions from "../permissions/usePermissions";
import { useSnackbar } from "notistack";
import ToolTip from "../../components/Tooltip/Tooltip";
import DisplayText from "../../components/DisplayText/DisplayText";
import ReportEligibility from "./ReportEligibility";
import { figureFlavor, mimeTypeToExtension } from "../../utils/MediaUtils";
import { prettyDate } from "../../utils/Dates";
import StudyCommentsDialog from "../studies/StudyCommentsDialog";
import InfoBox from "../studies/StudyCard/InfoBox";
import { getMediaViewerIdsFromUri } from "./utils";

interface IMediaViewerHeaderProps {
    mediaId: string;
    mediaViewerId: string;
    onVisitChange: (visitId: string) => void;
    isFullScreen: boolean;
    onToggleFullScreen: () => void;
    handleCloseMediaViewer: () => void;
    onOpenCRF: (selectedVisit: any) => void;
    onCompare: (selectedMediaId: string) => void;
}

const MediaViewerHeader: React.FC<IMediaViewerHeaderProps> = ({
    mediaId,
    onVisitChange,
    mediaViewerId,
    isFullScreen,
    onToggleFullScreen,
    handleCloseMediaViewer,
    onOpenCRF,
    onCompare,
}) => {
    const [noteDialogOpen, setNoteDialogOpen] = useState(false);
    const [copyQueryInfoOpen, setCopyQueryInfoOpen] = useState<boolean>(false);
    const [reportEligibilityOpen, setReportEligibilityOpen] = useState<boolean>(false);
    const { hasPermissions } = usePermissions();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const [addStudyComment] = useAddStudyCommentMutation();

    const { patientId } = useParams<{ patientId: string }>();

    const { data: patientData } = useGetPatientByIdQuery(patientId);

    const [deleteStudy] = useDeleteStudyMutation();
    const [downloadStudy] = useDownloadStudyMutation();

    const { data: studiesByEncounter } = useGetPatientStudiesByEncounterQuery({ patientId });

    const encounter = getMediaEncounter(studiesByEncounter, mediaId) as IEncounter;
    const selectedMediaViewerIds = getMediaViewerIdsFromUri(location.search) as string[];
    const media = selectMediaById(studiesByEncounter, mediaId) as IMedia;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const selectHandler = (event: SelectChangeEvent) => {
        const selectedVisitId = event.target.value;
        onVisitChange(selectedVisitId);
    };

    const handleOpenCopyCaseInfo = () => {
        setCopyQueryInfoOpen(false);
        // resetMailCopied();
    };

    const handleCloseCopyCaseInfo = () => {
        setCopyQueryInfoOpen(true);
    };

    const handleOpenReportEligibility = () => {
        setReportEligibilityOpen(true);
    };

    const handleCloseReportEligibility = () => {
        setReportEligibilityOpen(false);
    };

    const handleCompare = () => {
        onCompare(mediaViewerId);
    };

    const getStudyUrL = (study: any): string => {
        const fileExtension = study.fullUploadPath.split(".").pop();

        const flavor = figureFlavor(media);

        const kind = flavor === "oct-mp4" || flavor === "oct-photo" ? "oct-image" : fileExtension;

        return `/media/stream/${kind}/${study.uuid}?res=full`;
    };

    const handleDownloadStudy = async () => {
        enqueueSnackbar("Download is being prepared...", {
            variant: "info",
            persist: true,
        });

        try {
            const studyUrl = getStudyUrL(media);

            const { fileBlob, contentType } = await downloadStudy(studyUrl).unwrap();

            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([fileBlob]));
            // Get file extension
            const fileExtension = mimeTypeToExtension[contentType];

            // Crete link element
            const link = document.createElement("a");
            link.href = url;

            const fileNameWithExtension = `${media.fileName.substring(
                0,
                media.fileName.lastIndexOf(".")
            )}.${fileExtension}`;
            link.setAttribute("download", fileNameWithExtension);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link!.parentNode!.removeChild(link);

            closeSnackbar();
        } catch (e) {
            closeSnackbar();
            enqueueSnackbar("There was a problem with the download.", {
                variant: "error",
            });
        }
    };

    const handleDeleteStudy = async () => {
        try {
            await deleteStudy({ studyId: media.uuid, patientId: patientData!.patientId }).unwrap();
            handleCloseMediaViewer();
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
        }
    };

    const menuListOptions = [
        ...(hasPermissions(["QC"])
            ? [{ label: "Query: Copy Case Info", Icon: <ContentCopyIcon />, onClick: handleCloseCopyCaseInfo }]
            : []),
        ...(hasPermissions(["QC"])
            ? [{ label: "Report Eligibility", Icon: <ContentCopyIcon />, onClick: handleOpenReportEligibility }]
            : []),
        // Is not a site user
        ...(!hasPermissions(["Upload", "Review"])
            ? [
                  {
                      label: "Open CRF table",
                      Icon: <TableViewIcon />,
                      onClick: () => onOpenCRF(encounter),
                  },
              ]
            : []),
        ...(hasPermissions(["Download"])
            ? [
                  {
                      label: "Download",
                      Icon: <DownloadIcon />,
                      onClick: handleDownloadStudy,
                  },
              ]
            : []),
        ...(selectedMediaViewerIds.length >= 2
            ? [
                  {
                      label: "Compare with",
                      Icon: <CompareIcon />,
                      onClick: handleCompare,
                  },
              ]
            : []),
        ...(hasPermissions(["Delete"])
            ? [
                  {
                      label: "Remove",
                      Icon: <DeleteOutlineIcon color="error" />,
                      labelStyle: { color: "#f44336" },
                      onClick: handleDeleteStudy,
                  },
              ]
            : []),
    ];

    const handleOpenNoteDialog = () => setNoteDialogOpen(true);

    const handleAddComment = async (newNote: string) => {
        await addStudyComment({ mediaId: media.uuid, note: newNote, patientId: patientId }).unwrap();
        return;
    };

    const handleCloseNoteDialog = () => setNoteDialogOpen(false);

    const NotificationComponent = media.comments?.length ? (
        <ToolTip content={<DisplayText type="bodyXSmall" text={`Last: ${media.comments!.at(-1)!.comment}`} />}>
            <Box sx={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                <Chip
                    icon={<NoteIcon />}
                    onClick={handleOpenNoteDialog}
                    label="Notes"
                    size="small"
                    color={media.comments?.length ? "warning" : "default"}
                />
            </Box>
        </ToolTip>
    ) : (
        <Box sx={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
            <Chip
                icon={<NoteIcon />}
                onClick={handleOpenNoteDialog}
                label="Add notes"
                size="small"
                color={media.comments?.length ? "warning" : "default"}
            />
        </Box>
    );

    return (
        <>
            <CopyCaseInfo
                isOpen={copyQueryInfoOpen}
                study={media}
                visitId={encounter.visitId}
                visitName={encounter.visitName}
                siteName={patientData!.site}
                patientLocalId={patientData!.patientId}
                patientId={patientData!.uuid}
                onClose={handleOpenCopyCaseInfo}
            />
            <ReportEligibility
                siteName={patientData!.site}
                visitName={encounter.visitName}
                patientId={patientData!.patientId}
                isOpen={reportEligibilityOpen}
                study={media}
                onClose={handleCloseReportEligibility}
            />
            <Box
                sx={{
                    display: "flex",
                    bgcolor: "#0e1118",
                    flex: 1,
                    px: 20,
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Select
                    id="visit-dropdown"
                    value={encounter.uuid}
                    onChange={selectHandler}
                    variant="standard"
                    MenuProps={{
                        container: document.getElementById(`${mediaViewerId}-container`),
                        disablePortal: true,
                    }}
                >
                    {studiesByEncounter.map((encounter: IEncounter) => (
                        <MenuItem key={encounter.uuid} value={encounter.uuid}>
                            {prettyDate(encounter.encounterDate.toString())} - {encounter.visitName}
                        </MenuItem>
                    ))}
                </Select>
                {media.laterality ? <LateralityPill laterality={media.laterality} /> : null}
                <Box sx={{ display: "flex", alignContent: "center", gap: 5, alignItems: "center" }}>
                    {hasPermissions(["QC"]) ? NotificationComponent : null}
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                        sx={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                        }}
                        onClick={handlePopoverOpen}
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                    >
                        <InfoIcon fontSize="small" />
                    </Box>
                    <Popover
                        id="mouse-over-popover"
                        sx={{
                            pointerEvents: "none",
                        }}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        onClose={handlePopoverClose}
                        disablePortal
                        disableRestoreFocus
                    >
                        <InfoBox media={media} />
                    </Popover>
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                    <IconButton onClick={onToggleFullScreen} size="small">
                        {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </IconButton>
                    {menuListOptions.length > 0 ? (
                        <Box sx={{ mr: -9 }}>
                            <MenuList disablePortal={true} options={menuListOptions} />
                        </Box>
                    ) : null}
                </Box>
            </Box>
            <StudyCommentsDialog
                disablePortal={true}
                comments={media.comments}
                isOpen={noteDialogOpen}
                onAdd={handleAddComment}
                onClose={handleCloseNoteDialog}
            />
        </>
    );
};

export default MediaViewerHeader;
