import React, { memo, useState, useRef, useEffect } from "react";
import { Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import useGetStudyIdFromUri from "../../../hooks/useGetStudyIdFromUri/useGetStudyIdFromUri";
import { useGetPatientStudiesByEncounterQuery } from "../../studies/dataAccess";
import { getMediaEncounter } from "../viewerSlice";
import theme from "../../../app/theme";
import DisplayText from "../../../components/DisplayText/DisplayText";
import MediaViewerFile from "../MediaViewerFile";
import CompareMediaViewerHeader from "./CompareMediaViewerHeader";

interface IMediaViewerProps {
    mediaViewerId: string;
    sideLabel: "Left" | "Right";
}

const CompareMediaViewer: React.FC<IMediaViewerProps> = ({ mediaViewerId, sideLabel }) => {
    const { patientId } = useParams<{ patientId: string }>();

    const { getStudyIdByMediaViewerId } = useGetStudyIdFromUri();

    const mediaId = getStudyIdByMediaViewerId(mediaViewerId);

    const { data: studiesByEncounter } = useGetPatientStudiesByEncounterQuery({ patientId });

    const encounter = mediaId ? getMediaEncounter(studiesByEncounter, mediaId) : undefined;

    const [isFullScreen, setIsFullScreen] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleFullScreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };

        document.addEventListener("fullscreenchange", handleFullScreenChange);
        return () => {
            document.removeEventListener("fullscreenchange", handleFullScreenChange);
        };
    }, [mediaViewerId]);

    return (
        <Paper
            ref={containerRef}
            sx={{
                border: "1px solid",
                borderColor: "#0e1118",
                display: "flex",
                flexDirection: "column",
                aspectRatio: "16 / 13",
                transition: "all 0.3s ease-in-out",
            }}
            id={`${mediaViewerId}-container`}
        >
            <Box sx={{ display: "flex" }}>
                <Box
                    sx={{
                        height: 40,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flex: "0 1 140px",
                        maxWidth: 300,
                        backgroundColor: "#0e1118",
                        borderRadius: "0px 0px 6px 0px",
                        zIndex: 2,
                        marginRight: -5,
                    }}
                >
                    {encounter ? (
                        <Box sx={{ width: "100%" }}>
                            <Box
                                sx={{
                                    flex: 1,
                                    width: "100%",
                                    overflow: "hidden",
                                    p: 5,
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                            >
                                <DisplayText
                                    style={{
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                    }}
                                    type="bodyMedium"
                                    text={`${encounter.visitName} - ${new Date(
                                        encounter.encounterDate
                                    ).toLocaleDateString()}`}
                                />
                            </Box>
                        </Box>
                    ) : null}
                </Box>
                {encounter && <CompareMediaViewerHeader mediaId={mediaId as string} />}
            </Box>
            {encounter && (
                <>
                    <Box
                        sx={{
                            width: "100%",
                            p: 10,
                            flex: 1,
                            display: "flex",
                            justifyContent: sideLabel === "Left" ? "flex-end" : "flex-start",
                            alignItems: "center",
                            overflow: "auto",
                        }}
                    >
                        <MediaViewerFile
                            isFullScreen={isFullScreen}
                            key={mediaId}
                            mediaViewerId={mediaViewerId}
                            mediaId={mediaId as string}
                        />
                    </Box>
                </>
            )}
        </Paper>
    );
};

export default memo(CompareMediaViewer);
