import { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
} from "@mui/material";
import DisplayText from "../../components/DisplayText/DisplayText";
import { useGetTrialVisitsQuery } from "../upload/dataAccess"; // Adjust the import path as necessary
import { useAppSelector } from "../../app/hooks"; // Assuming you have a way to get the selected trial ID
import { getSelectedTrialId } from "../auth/login/loginSlice";

interface MoveItemsModalProps {
    isOpen: boolean;
    onClose: () => void;
    selectedItems: Set<string>;
    onAccept: (targetVisitId: string) => void;
    isLoading?: boolean;
}

const MoveItemsModal = ({ isOpen, onClose, selectedItems, onAccept, isLoading }: MoveItemsModalProps) => {
    const [selectedVisit, setSelectedVisit] = useState<string>("");
    const selectedTrialId = useAppSelector(getSelectedTrialId); // Assuming you have a way to get the selected trial ID
    const { data: trialVisits, isLoading: visitsLoading } = useGetTrialVisitsQuery(selectedTrialId);

    const handleAccept = () => {
        if (selectedVisit) {
            onAccept(selectedVisit);
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Move Items</DialogTitle>
            <DialogContent>
                <Box sx={{ mb: 2 }}>
                    <DisplayText
                        type="bodyMedium"
                        text={`Selected items to move: ${selectedItems.size}`}
                        variant="regular"
                    />
                </Box>
                <FormControl fullWidth sx={{ mt: 12 }}>
                    <InputLabel id="visit-select-label">{`${
                        !trialVisits ? "Loading visits..." : "Target Visit"
                    }`}</InputLabel>
                    <Select
                        labelId="visit-select-label"
                        value={selectedVisit}
                        label="Target Visit"
                        onChange={(e) => setSelectedVisit(e.target.value)}
                        disabled={isLoading || visitsLoading}
                    >
                        {trialVisits?.map((visit) => (
                            <MenuItem key={visit.uuid} value={visit.uuid}>
                                {visit.visitName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={isLoading}>
                    Cancel
                </Button>
                <Button onClick={handleAccept} variant="contained" disabled={!selectedVisit || isLoading}>
                    {isLoading ? "Moving..." : "Move"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MoveItemsModal;
