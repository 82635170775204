import React, { memo, useState, useRef, useEffect } from "react";
import { Paper, IconButton } from "@mui/material";
import MediaViewerFile from "./MediaViewerFile";
import MediaViewerHeader from "./MediaViewerHeader";
import { Close } from "@mui/icons-material";
import Carousel from "../carousel/Carousel";
import theme from "../../app/theme";
import { getMediaEncounter } from "./viewerSlice";
import { IEncounter } from "../studies/studiesSlice";
import { useGetPatientStudiesByEncounterQuery } from "../studies/dataAccess";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useGetStudyIdFromUri from "../../hooks/useGetStudyIdFromUri/useGetStudyIdFromUri";
import queryString from "query-string";
import { getQueryParamFromStudyId } from "./utils";
import DisplayText from "../../components/DisplayText/DisplayText";
import { Box } from "@mui/system";
import Tooltip from "../../components/Tooltip/Tooltip";

interface IMediaViewerProps {
    mediaViewerId: string;
    onOpenCRF: (selectedEncounter: IEncounter) => void;
    onCompare: (selectedMediaId: string) => void;
}

const MediaViewer: React.FC<IMediaViewerProps> = ({ mediaViewerId, onOpenCRF, onCompare }) => {
    const history = useHistory();
    const location = useLocation();

    const { patientId } = useParams<{ patientId: string }>();

    const { getStudyIdByMediaViewerId, getMediaViewerIdIndex, selectedMediaViewerIds } = useGetStudyIdFromUri();

    const mediaId = getStudyIdByMediaViewerId(mediaViewerId);
    const mediaCardIndex = getMediaViewerIdIndex(mediaViewerId);

    const { data: studiesByEncounter } = useGetPatientStudiesByEncounterQuery({ patientId });

    const encounter = mediaId ? getMediaEncounter(studiesByEncounter, mediaId) : undefined;

    const [isFullScreen, setIsFullScreen] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);

    const handleToggleFullScreen = () => {
        if (!document.fullscreenElement) {
            containerRef.current?.requestFullscreen();
            setIsFullScreen(true);
        } else {
            document.exitFullscreen();
            setIsFullScreen(false);
        }
    };

    useEffect(() => {
        const handleFullScreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };

        document.addEventListener("fullscreenchange", handleFullScreenChange);
        return () => {
            document.removeEventListener("fullscreenchange", handleFullScreenChange);
        };
    }, []);

    const handleCarouselItemClick = (studyId: string) => {
        const updatedStudiesIds = [...selectedMediaViewerIds];

        const currentCardIndexIdentifier = updatedStudiesIds[mediaCardIndex].split(":")[0];

        updatedStudiesIds[mediaCardIndex] = `${currentCardIndexIdentifier}:${studyId}`;

        history.replace({
            pathname: location.pathname,
            search: queryString.stringify({ st: updatedStudiesIds }),
        });
    };

    const handleVisitChange = (encounterId: string) => {
        // find encounter by id
        const encounter = studiesByEncounter!.find(
            (encounter: IEncounter) => encounter.uuid === encounterId
        ) as IEncounter;
        // const visit = data!.studies!.find((visit) => visit.imagingProcedure.uuid === visitId) as IStudy;

        // find first media in encounter -> studies -> series -> medias
        const firstMedia = encounter.studies[0].series[0].medias[0];
        const updatedStudiesIds = [...selectedMediaViewerIds];

        updatedStudiesIds[mediaCardIndex] = getQueryParamFromStudyId(firstMedia.uuid);

        history.replace({
            pathname: location.pathname,
            search: queryString.stringify({ st: updatedStudiesIds }),
        });
    };

    const handleCloseMediaViewer = () => {
        const updatedStudiesIds = [...selectedMediaViewerIds];

        updatedStudiesIds.splice(mediaCardIndex, 1);

        history.replace({
            pathname: location.pathname,
            search: queryString.stringify({ st: updatedStudiesIds }),
        });
    };

    return (
        <Paper
            ref={containerRef}
            sx={{
                border: "3px solid",
                borderColor: theme.palette.success.main,
                display: "flex",
                flexDirection: "column",
                aspectRatio: "2.4",
                transition: "all 0.3s ease-in-out",
            }}
            id={`${mediaViewerId}-container`}
        >
            <Box sx={{ display: "flex" }}>
                <Box
                    sx={{
                        height: 40,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flex: "0 1 140px",
                        maxWidth: 140,
                        backgroundColor: theme.palette.success.main,
                        borderRadius: "0px 0px 6px 0px",
                        zIndex: 2,
                        marginRight: -5,
                    }}
                >
                    {encounter ? (
                        <Box sx={{ width: "100%" }}>
                            <Tooltip
                                content={`${encounter.visitName} - ${new Date(
                                    encounter.encounterDate
                                ).toLocaleDateString()}`}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                        color: "black",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <Box sx={{ flex: 1, width: "100%", overflow: "hidden", p: 5 }}>
                                        <DisplayText
                                            style={{
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                            }}
                                            type="bodyMedium"
                                            text={encounter.visitName}
                                        />
                                    </Box>

                                    <IconButton onClick={handleCloseMediaViewer} size="small">
                                        <Close
                                            sx={{
                                                height: theme.spacing(15),
                                                width: theme.spacing(15),
                                                color: "black",
                                            }}
                                        />
                                    </IconButton>
                                </Box>
                            </Tooltip>
                        </Box>
                    ) : null}
                </Box>
                {!encounter ? null : (
                    <MediaViewerHeader
                        onOpenCRF={onOpenCRF}
                        handleCloseMediaViewer={handleCloseMediaViewer}
                        isFullScreen={isFullScreen}
                        onToggleFullScreen={handleToggleFullScreen}
                        mediaViewerId={mediaViewerId}
                        onVisitChange={handleVisitChange}
                        mediaId={mediaId as string}
                        onCompare={onCompare}
                    />
                )}
            </Box>

            {!encounter ? (
                <Box
                    sx={{
                        height: "507px",
                        margin: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        p: 24,
                    }}
                >
                    <DisplayText type="bodyLarge" variant="semiBold" text="You don't have access to this study" />
                    <DisplayText
                        type="bodySmall"
                        variant="regular"
                        text="Make sure the study exists. In case it is, please ask for authorization to your system administrator."
                        style={{ textAlign: "center" }}
                    />
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            width: "100%",
                            p: 10,
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "auto",
                        }}
                    >
                        <MediaViewerFile
                            isFullScreen={isFullScreen}
                            key={mediaId}
                            mediaViewerId={mediaViewerId}
                            mediaId={mediaId as string}
                        />
                    </Box>
                    <Box sx={{ flex: 0 }}>
                        <Carousel
                            encounter={encounter}
                            selectedStudyId={mediaId as string}
                            onClick={handleCarouselItemClick}
                        />
                    </Box>
                </>
            )}
        </Paper>
    );
};

export default memo(MediaViewer);
