import { StudyService, MediaToken } from "./nebula.service";
import { nebulaClient, nebulaClient2, nebulaMediaClient, nebulaUploadClient } from "../../lib/nebulaClient";
import { Analysis, Attachment, ICRFData, IEncounterComment, IMedia } from "./studiesSlice";

export interface MimeTypeDTO {
    label: string;
    prettyLabel: string;
}

export interface ImagingProcedureDTO {
    orderDate: string;
    visit?: string;
    visitUuid: string;
    procedureIdentifier: string;
    site: string;
    trialName: string;
    studyUid: string;
    identifierType: string;
    reason: string;
    uuid: string;
}

export interface IMediaOctFileDTO {
    attachments: string[];
    mediaDateAndTime: string;
    originalDob: string;
    originalPatientId: string;
    originalPatientFirstName: string;
    originalPatientLastName: string;
    trialName: string;
    fullUploadPath: string;
    uuid: string;
    mimeType: MimeTypeDTO;
    ScanDateAndTime: string;
    ScanPattern: string;
    Laterality: string;
    Manufacturer: string;
    Device: string;
    FileName: string;
    ImageQuality: number;
    ImageQualityDisplayText: string;
    RasterAngleInDeg: number;
    FirstBscanStartXInMm: number;
    FirstBscanStartYInMm: number;
    LastBscanStartXInMm: number;
    LastBscanStartYInMm: number;
    BscanHeightDim: number;
    BscanWidthDim: number;
    NumSlices: number;
    BscanWidthSizeInMicrons: number;
    BscanHeightSizeInMicrons: number;
    NumSlicesSizeInMicrons: number;
    UniqueId: string;
    SiteId: string;
    SeriesId: number;
    Comment: string;
    visit: string;
    ThumbNailFileName: string;
}

export interface VisitDTO {
    imagingProcedure: ImagingProcedureDTO;
    mediaOctFile: IMediaOctFileDTO[];
    crfDataSet: ICRFData;
    hasMedia: boolean;
    isLoadingMedia: boolean;
}

export interface ImagingDataset {
    imagingProcedure: ImagingProcedureDTO;
    mediaOctFile: IMediaOctFileDTO[];
}

export interface EncounterDTO {
    encounter: {
        encounterDate: string;
        encounterIdentifier: string;
        identifierType: string;
        identifierUuid: string;
        encounterUuid: string;
        reason: string;
    };
    imagingDataSet: ImagingDataset[];
}

export interface EncountersDTO {
    patient: {
        comment: string;
        dob: string;
        dod: string;
        birthName: string;
        birthGender: string;
        localId: string;
        isQc: boolean;
    };
    encounters: EncounterDTO[];
    crfDataSet: ICRFData;
}

export interface StudyDto {
    uuid: string;
    visitId: string;
    patientId: string;
    siteId: string;
    encounterId: string;
    series: SerieDto[];
    createdOn: Date;
    updatedOn: Date;
}

export type MediaCategory = "original" | "raw" | "voxDicom" | "derived" | "attached" | "analysis";

export interface SerieDto {
    uuid: string;
    medias: MediaDto[];
    createdOn: Date;
    updatedOn: Date;
}

export interface MediaDto {
    uuid: string;
    category: MediaCategory;
    mimeType: MimeType;
    mediaUrl: string;
    metadata: object;
    notes: string;
    updatedOn: Date;
    dicom: Record<string, any>;
}

export interface EncounterDto {
    uuid: string;
    studies: StudyDto[];
    visitName: string;
    visitId: string;
    encounterDate: Date;
}

export const studyService: StudyService = {
    // @ts-ignore
    getPatientStudies: async (patientId: string) => {
        const response = await nebulaClient.get<EncountersDTO>(`/study/encounters/patient/${patientId}`);

        if (response.error) {
            return response;
        }

        const { crfDataSet, encounters } = response.data as EncountersDTO;

        const enrichImageDataSet = (encounterData: object, imagingDataSetArray: ImagingDataset[]) => {
            return imagingDataSetArray.map((imageDataSet) => {
                return {
                    imagingProcedure: { ...imageDataSet.imagingProcedure, ...encounterData },
                    mediaOctFile: imageDataSet.mediaOctFile.map((media) => ({ ...media, ...encounterData })),
                };
            });
        };

        const studies = encounters
            .map((encounter: any) =>
                enrichImageDataSet(
                    {
                        encounterUuid: encounter.encounter.encounterUuid,
                        encounterIdentifier: encounter.encounter.encounterIdentifier,
                    },
                    encounter.imagingDataSet
                )
            )
            .flat();

        return { data: { crfs: crfDataSet, studies } };
    },
    // @ts-ignore
    getPatientStudiesV2: async (patientId: string, showHidden?: boolean) => {
        const path = showHidden ? `/patient/${patientId}/study?showHidden=true` : `/patient/${patientId}/study`;
        const response = await nebulaClient2.get<EncounterDto[]>(path);

        return response;
    },
    getMediaToken: async (expiration?: string) => {
        let url = `/media/token${expiration ? `?expireInSec=${expiration}` : ""}`;

        const response = await nebulaClient.get<MediaToken>(url);

        return response;
    },
    deleteStudy: async (studyId: string) => {
        const response = await nebulaClient.delete<void>(`entity/soft/Media/${studyId}`);

        return response;
    },
    attachFile: async (newFile: FormData) => {
        const response = await nebulaUploadClient.post<void>(`/study/media/attach`, newFile);

        return response;
    },

    downloadStudy: async (studyUrl: string) => {
        const response = await nebulaMediaClient.get<Blob>(studyUrl, { responseType: "blob" });

        if (response.error) {
            return response;
        }

        return {
            data: {
                fileBlob: response.data,
                contentType: response.meta!.headers?.["content-type"] as string,
            },
        };
    },
    addStudyComment: async (patientId: string, mediaId: string, note: string) => {
        const response = await nebulaClient2.post<unknown>(`/patient/${patientId}/media/${mediaId}/comment`, {
            comment: note,
        });

        return response;
    },
    getAnalysisInfo: async (patientId: string, mediaId: string) => {
        const response = await nebulaClient2.get<Analysis[]>(`/patient/${patientId}/media/${mediaId}/analysis-info/`);

        return response;
    },
    addEncounterComment: async (patientId: string, encounterId: string, comment: string) => {
        const response = await nebulaClient2.post<IEncounterComment>(
            `/patient/${patientId}/encounter/${encounterId}/comment`,
            {
                comment,
            }
        );

        return response;
    },
    deleteVisit: async (patientId: string, visitId: string) => {
        const response = await nebulaClient2.delete<void>(`/patient/${patientId}/visit/${visitId}`);

        return response;
    },
    editMediaVisibility: async (patientId: string, mediaId: string, visible: boolean) => {
        const response = await nebulaClient2.put<IMedia>(`/patient/${patientId}/media/${mediaId}/visibility`, {
            visible,
        });

        return response;
    },
    editStudyDate: async (patientId: string, studyId: string, newDate: string) => {
        const response = await nebulaClient2.put<unknown>(`/patient/${patientId}/study/${studyId}`, {
            newDate: newDate,
        });
        return response;
    },
    moveSeries: async (patientId: string, studyId: string, visitId: string, seriesIds: string[]) => {
        const response = await nebulaClient2.put<void>(`/patient/${patientId}/study/${studyId}/move`, {
            seriesIds,
            visitId,
        });
        return response;
    },
};
