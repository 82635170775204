import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectUploadState, UploadStep, resetUploadSession, selectFileEntries } from "./uploadSlice";
import { useGetTrialVisitsQuery, useGetUploadConfigQuery } from "./dataAccess";
import {
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    Container,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Alert,
} from "@mui/material";
import theme from "../../app/theme";
import FileUpload from "./components/FileUpload/FileUpload";
import { Box } from "@mui/system";
import FileViewer from "./components/FileViewer/FileViewer";
import { useConfirmationModal } from "../../components/ConfirmationDialog";
import { getSelectedTrialId } from "../auth/login/loginSlice";
import DisplayText from "../../components/DisplayText/DisplayText";

const Upload = () => {
    const uploadStep = useAppSelector(selectUploadState);
    const selectedTrialId = useAppSelector(getSelectedTrialId);
    const [checkboxChecked, setCheckboxChecked] = useState(false);

    const dispatch = useAppDispatch();
    const [selectedVisitId, setSelectedVisitId] = useState<string>("");
    const fileEntries = useAppSelector(selectFileEntries);

    const { openConfirmationModal } = useConfirmationModal({
        title: "You upload will be cancelled",
        content: "Changing the visit will cancel this upload.",
        acceptLabel: "Change Visit",
        cancelLabel: "Go back",
    });

    const { data: uploadConfig, isFetching: uploadConfigLoading } = useGetUploadConfigQuery(selectedTrialId);
    const { data: trialVisits } = useGetTrialVisitsQuery(selectedTrialId);

    const handleVisitIdentifierChange = (e: SelectChangeEvent) => {
        const selectVisit = () => setSelectedVisitId(e.target.value);
        if (fileEntries.length > 0)
            return openConfirmationModal(() => {
                dispatch(resetUploadSession());
                selectVisit();
            });

        selectVisit();
    };

    useEffect(() => {
        setSelectedVisitId("");
    }, [trialVisits]);

    const selectedVisit = useMemo(() => {
        const selectedVisit = trialVisits?.find((visit) => visit.uuid === selectedVisitId);

        return selectedVisit;
    }, [trialVisits, selectedVisitId]);

    const renderUploadStep = () => {
        if (uploadStep === UploadStep.FILE_UPLOAD) {
            return (
                <div>
                    <FileUpload
                        validFileExtensions={uploadConfig?.validExtensions?.join(",")}
                        folderConventionRegEx={uploadConfig?.folderConventionRegEx}
                        uploadSizeLimitMb={uploadConfig?.uploadSizeLimitMb}
                        validFolderNames={uploadConfig?.validExampleFolders}
                        selectedVisitId={selectedVisitId}
                        disabled={!selectedVisitId}
                        isQC={selectedVisit?.certification ? "true" : ""}
                    />
                </div>
            );
        }

        return <FileViewer />;
    };

    const renderVisitIdentifiersItems = (uuid: string, label: string, active: Boolean) => (
        <MenuItem key={uuid} value={uuid} disabled={!active}>
            {label}
        </MenuItem>
    );

    const visitItems = useMemo(() => {
        if (!trialVisits) return;

        return trialVisits.map(({ uuid, visitName, active }) => renderVisitIdentifiersItems(uuid, visitName, active));
    }, [trialVisits]);

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!checkboxChecked) {
            setCheckboxChecked(event.target.checked);
        }
    };

    return (
        <Container sx={{ padding: theme.spacing(20) }} component="main" maxWidth="lg">
            <Box sx={{ p: 20 }}>
                <Typography variant="h5" gutterBottom component="h5">
                    File Upload
                </Typography>
                <Typography variant="subtitle1" gutterBottom component="p">
                    Files will be assigned to visit:
                </Typography>
                <Box sx={{ display: "flex", gap: 10 }}>
                    <Box>
                        <Box sx={{ display: "flex", gap: 10 }}>
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <InputLabel
                                    sx={{ color: !checkboxChecked || uploadConfigLoading ? "#626872" : "inherit" }}
                                >
                                    {uploadConfigLoading ? "Loading..." : "Select visit"}
                                </InputLabel>
                                <Select
                                    autoWidth={false}
                                    label="Select visit"
                                    id="selectVisitDropdown"
                                    data-test-id="upload:visitSelector"
                                    value={selectedVisitId}
                                    onChange={handleVisitIdentifierChange}
                                    disabled={!checkboxChecked || uploadConfigLoading}
                                >
                                    {visitItems}
                                </Select>
                            </FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{
                                            color: "#626872", // changes the color of the checkbox when it's checked
                                        }}
                                        checked={checkboxChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label="I confirm I am submitting the best available images captured at this visit."
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row?", gap: 10 }}>
                            {selectedVisit?.modalitiesRequired ? (
                                <Box sx={{ mt: 16 }}>
                                    <Alert variant="outlined" severity="info" icon={false}>
                                        <DisplayText
                                            text={`Modalities: `}
                                            type="bodySmall"
                                            variant="semiBold"
                                            style={{ display: "inline" }}
                                        />
                                        <DisplayText
                                            text={`${selectedVisit?.modalitiesRequired}`}
                                            type="bodySmall"
                                            style={{ display: "inline" }}
                                        />
                                    </Alert>
                                </Box>
                            ) : null}
                            {selectedVisit?.description ? (
                                <Box sx={{ mt: 16 }}>
                                    <Alert variant="outlined" severity="info" icon={false}>
                                        <DisplayText
                                            text={`Visit Information: `}
                                            type="bodySmall"
                                            variant="semiBold"
                                            style={{ display: "inline" }}
                                        />
                                        <DisplayText
                                            text={`${selectedVisit?.description}`}
                                            type="bodySmall"
                                            style={{ display: "inline" }}
                                        />
                                    </Alert>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ mt: 16 }}>{renderUploadStep()}</Box>
            </Box>
        </Container>
    );
};

export default Upload;
