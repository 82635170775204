import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useMemo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getSelectedTrial, selectTrial, setTrialHasManual } from "../auth/login/loginSlice";
import { ITrial } from "../upload/uploadSlice";
import { useGetTrialsQuery } from "../upload/dataAccess";
import { useSnackbar } from "notistack";
import useQueryParams from "../../hooks/useQueryParams/useQueryParams";
import { useCheckIfTrialHasManualMutation, useUpdateCurrentGroupMutation } from "../auth/dataAccess";
import { useLocation } from "react-router-dom";

const TrialDropdown = () => {
    const dispatch = useAppDispatch();
    const { removeQueryParam } = useQueryParams();

    const { enqueueSnackbar } = useSnackbar();
    const [updateCurrentGroup] = useUpdateCurrentGroupMutation({
        fixedCacheKey: "groupChange",
    });
    const location = useLocation();
   /********************************************************************************
   * Note: REMOVE when we figure out what's going on with switching trials (refer to NI-2649) let's
   * prevent the user from switching trials while in the Ground View (currently /visits path
   ********************************************************************************/
    const isVisitsRoute = useMemo(() => {
        const match = location.pathname.match(/^\/visits\/[0-9a-fA-F-]{36}$/);
        return match !== null;
    }, [location.pathname]);

    const { data: trials } = useGetTrialsQuery();
    const selectedTrial = useAppSelector(getSelectedTrial);
    const [checkHasManual, { data: hasManual }] = useCheckIfTrialHasManualMutation();

    useEffect(() => {
        dispatch(setTrialHasManual(!!hasManual));
    }, [hasManual, selectedTrial]);

    // Validate and activate trial on component mount
    useEffect(() => {
        if (!trials) return;

        if (!selectedTrial) {
            dispatch(selectTrial(trials[0].uuid));
            checkHasManual(trials[0].uuid);
            updateCurrentGroup(trials[0].uuid).catch(() => {
                enqueueSnackbar("Failed to activate trial", { variant: "error" });
            });
            return;
        }

        // Check if selected trial belongs to user's trial list
        const selectedTrialBelongsToUser = trials.some((trial: ITrial) => trial.uuid === selectedTrial.uuid);

        // If trial doesn't belong to user, select and activate first available trial
        if (!selectedTrialBelongsToUser && trials.length > 0) {
            const firstTrialId = trials[0].uuid;
            dispatch(selectTrial(firstTrialId));
            checkHasManual(firstTrialId);
            updateCurrentGroup(firstTrialId).catch(() => {
                enqueueSnackbar("Failed to activate trial", { variant: "error" });
            });
            return;
        }
        checkHasManual(selectedTrial.uuid);
        // Activate the valid trial
        updateCurrentGroup(selectedTrial.uuid).catch(() => {
            enqueueSnackbar("Failed to activate trial", { variant: "error" });
        });
    }, [trials]);

    const handleChangeGroup = async (e: any) => {
        const currentSelectedTrial = selectedTrial!.uuid;
        const selectedGroup = e.target.value;
        try {
            dispatch(selectTrial(selectedGroup));
            checkHasManual(selectedGroup);
            removeQueryParam("selectedPatient", true);
            await updateCurrentGroup(selectedGroup).unwrap();
        } catch (e) {
            dispatch(selectTrial(currentSelectedTrial));
            checkHasManual(currentSelectedTrial);
            enqueueSnackbar("Something wrong happened.", {
                variant: "error",
            });
        }
    };

    const renderTrialItem = (trial: ITrial) => (
        <MenuItem key={trial.uuid} value={trial.uuid}>
            {trial.name}
        </MenuItem>
    );

    const trialItems = useMemo(() => {
        if (!trials) return;

        return trials.map(renderTrialItem);
    }, [trials]);

    return (
        <FormControl size="small" sx={{ m: 1, minWidth: 250 }}>
            <InputLabel>{!trials ? "Loading..." : "Trials"}</InputLabel>
            <Select
                autoWidth={false}
                label="Loading..."
                value={selectedTrial?.uuid || ""}
                disabled={!selectedTrial || isVisitsRoute}
                onChange={handleChangeGroup}
            >
                {trialItems}
            </Select>
        </FormControl>
    );
};

export default TrialDropdown;
