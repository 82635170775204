import React, { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CircularProgress, Button, Paper, Divider, Tabs, Tab, LinearProgress } from "@mui/material";
import { useDeleteStudyMutation, useGetPatientStudiesByEncounterQuery, useMoveSeriesMutation } from "./dataAccess";
import { selectCurrentPatient } from "../patients/patientsSlice";
import PatientInfo from "./PatientInfo";
import { Box } from "@mui/system";
import { useHistory } from "react-router-dom";
import DrawerHeader from "./DrawerHeader";
import DisplayText from "../../components/DisplayText/DisplayText";
import queryString from "query-string";
import { getQueryParamFromStudyId } from "../media/utils";
import TabPanel from "../../components/Tab/TabPanel";
import CRFTab from "./CRFTab";
import StudiesFilters from "./StudiesFilters";
import { useGetPatientCrfQuery } from "../crf/dataAccess";
import useRoles from "../permissions/useRoles";
import VisitAccordion from "./VisitPanel/VisitAccordion";
import SplitButton from "../../components/SplitButton/SplitButton";
import EditModality from "../media/EditModality";
import { useConfirmationModal } from "../../components/ConfirmationDialog";
import { useSnackbar } from "notistack";

import useQueryParams from "../../hooks/useQueryParams/useQueryParams";
import { selectShowHidden, toggleShowHidden } from "./studiesSlice";
import usePermissions from "../permissions/usePermissions";
import MoveItemsModal from "./MoveItemsModal";

const Studies = () => {
    const currentPatient = useAppSelector(selectCurrentPatient);
    const showHiddenFiles = useAppSelector(selectShowHidden);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { userHasRole } = useRoles();
    const { getQueryParam } = useQueryParams();
    const [deleteStudy] = useDeleteStudyMutation();
    const { enqueueSnackbar } = useSnackbar();
    const [moveSeries] = useMoveSeriesMutation();
    const [isMoving, setIsMoving] = useState(false);

    const { hasPermissions } = usePermissions();
    const showCrf = useMemo(
        () =>
            userHasRole("READER") ||
            userHasRole("CRA") ||
            userHasRole("PROJECT_MANAGER") ||
            userHasRole("SUPER_READER") ||
            userHasRole("SPONSOR"),
        []
    );

    const handleShowHiddenFiles = () => {
        dispatch(toggleShowHidden());
    };

    const {
        data: encounters,
        error: studiesLoadingError,
        isLoading: areStudiesLoading,
        isFetching,
        refetch: refetchStudies,
        // @ts-ignore
    } = useGetPatientStudiesByEncounterQuery(
        { patientId: currentPatient.uuid, showHidden: showHiddenFiles },
        { refetchOnMountOrArgChange: true }
    );

    const {
        crfVisits,
        error: crfError,
        isLoading: crfLoading,
    } = useGetPatientCrfQuery(currentPatient.uuid, {
        skip: !showCrf,
        pollingInterval: 15_000,
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ data, ...results }) => ({
            ...results,
            crfVisits: data ? data.visits : [],
        }),
    });

    const selectedVisit = useMemo(() => {
        if (!encounters) return null;
        const selectedVisitId = getQueryParam("selectedVisit");
        if (!selectedVisitId) return null;

        const selectedVisit = encounters.find((encounter: any) => encounter.visitId === selectedVisitId);
        if (!selectedVisit) return null;

        return selectedVisit;
    }, [encounters]);

    const [selectedStudies, setSelectedStudies] = useState<Set<string>>(new Set());
    const [editModalityOpen, setEditModalityOpen] = useState<boolean>(false);
    const [moveItemsModalOpen, setMoveItemsModalOpen] = useState<boolean>(false);

    const { openConfirmationModal } = useConfirmationModal({
        title: `Are you sure you want to delete ${Array.from(selectedStudies).length} image${
            Array.from(selectedStudies).length > 1 ? "s" : ""
        }?`,
        content: "",
        acceptLabel: "Remove",
    });

    const [tabValue, setTabValue] = React.useState(0);

    // Get Medias from all encounters using useMemo
    const medias = useMemo(() => {
        if (!encounters) return [];

        return encounters
            .map((encounter: any) => {
                // unwrap studies, series and medias from encounter
                return encounter.studies.map((study: any) =>
                    study.series.map((serie: any) => serie.medias.map((media: any) => media))
                );
            })
            .flat(3);
    }, [encounters]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleSelect = useCallback(
        (ids: string[], isChecked: boolean) => {
            const newSelectedStudies = new Set(selectedStudies);

            if (isChecked) {
                setSelectedStudies((prev) => {
                    return new Set([...Array.from(prev), ...ids]);
                });
            } else {
                setSelectedStudies((prev) => {
                    return new Set([...Array.from(prev).filter((id) => !ids.includes(id))]);
                });
            }
        },
        [selectedStudies]
    );

    const handleOpenEditModality = () => {
        setEditModalityOpen(true);
    };

    const handleCloseEditModality = () => {
        setEditModalityOpen(false);
    };

    const selectAllAvailable = useMemo(() => {
        return selectedStudies.size > 0;
    }, [selectedStudies]);

    const handleViewAll = () => {
        const selectedStudiesIds = Array.from(selectedStudies);

        if (selectedStudiesIds.length === 0) return;

        // Adding random id before id as query params to be able to differentiate when having to media cards
        // with the same id. This is important for example when closing a card
        const studiesIdsAsQueryParams = Array.from(selectedStudies).map(getQueryParamFromStudyId);

        history.push({
            pathname: `/visits/${currentPatient!.uuid}`,
            search: queryString.stringify({ st: studiesIdsAsQueryParams }),
        });
    };

    // @ts-ignore
    const crfNotAllowed = useMemo(() => crfError && crfError.status === 401, [crfError]);
    // @ts-ignore
    const crfErrorHappened = useMemo(() => crfError && crfError.status !== 401, [crfError]);

    const handleSelectStudies = useCallback((studiesIds) => {
        setSelectedStudies(new Set(studiesIds));
    }, []);

    const ErrorComponent = () => {
        return (
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <DisplayText type="bodyMedium" text="An error occurred" />
                    <Button size="small" variant="contained" onClick={refetchStudies}>
                        Retry
                    </Button>
                </Box>
            </Box>
        );
    };

    const LoadingComponent = () => {
        return (
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <CircularProgress />
            </Box>
        );
    };

    const handleBulkDelete = () => {
        return openConfirmationModal(async () => {
            const currentStudies = Array.from(selectedStudies);
            try {
                setSelectedStudies(new Set());
                await Promise.all(
                    Array.from(selectedStudies).map(
                        async (mediaId) =>
                            await deleteStudy({ studyId: mediaId, patientId: currentPatient!.uuid }).unwrap()
                    )
                );
            } catch (e) {
                setSelectedStudies(new Set(currentStudies));
                enqueueSnackbar("Something went wrong.", { variant: "error" });
            }
        });
    };

    const handleOpenMoveItems = () => {
        setMoveItemsModalOpen(true);
    };

    const handleCloseMoveItems = () => {
        setMoveItemsModalOpen(false);
    };

    const handleMoveItems = async (targetVisitId: string) => {
        // Get all series IDs from the selected media IDs
        const seriesIds = new Set<string>();
        let studyId: string | undefined;

        encounters.forEach((encounter: any) => {
            encounter.studies.forEach((study: any) => {
                study.series.forEach((series: any) => {
                    series.medias.forEach((media: any) => {
                        if (selectedStudies.has(media.uuid)) {
                            seriesIds.add(series.uuid);
                            if (!studyId) {
                                studyId = study.uuid;
                            }
                        }
                    });
                });
            });
        });

        if (!studyId) {
            enqueueSnackbar("No study found for the selected items.", { variant: "error" });
            return;
        }

        try {
            setIsMoving(true);
            await moveSeries({
                patientId: currentPatient!.uuid,
                studyId,
                visitId: targetVisitId,
                seriesIds: Array.from(seriesIds),
            }).unwrap();
            setSelectedStudies(new Set());
            handleCloseMoveItems();
        } catch (error) {
            const errorCode = (error as any)?.data?.code;
            const possibleErrorMessages: { [key: string]: string } = {
                "CANNOT.MOVE.ALL.SERIES": "Cannot move all series from a visit.",
                "SERIES.NOT.FOUND.IN.STUDY": "Can only move series from one visit at a time.",
                "CANNOT.MOVE.ALL.SERIES.DUE.TO.WORKFLOW.STATE":
                    "Cannot move all series from a visit with workflow state already started.",
                "CANNOT.MOVE.ALL.SERIES.DUE.TO.CRF.DATA": "Cannot move all series from a visit with CRF data.",
            };

            const errorMessage = possibleErrorMessages[errorCode] || "Something went wrong while moving the items.";

            enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
            setIsMoving(false);
        }
    };

    return (
        <Paper sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            {isFetching && <LinearProgress />}
            <DrawerHeader />
            <Divider />
            <Box
                sx={{
                    px: 16,
                    py: 24,
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                }}
            >
                <PatientInfo />
                {!isFetching && studiesLoadingError ? (
                    <ErrorComponent />
                ) : (
                    <>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Studies" />
                                {showCrf ? <Tab label="CRF" /> : null}
                            </Tabs>
                        </Box>
                        <TabPanel
                            value={tabValue}
                            index={0}
                            style={{ overflow: "hidden", flex: 1 }}
                            containerStyle={{ display: "flex", flexDirection: "column" }}
                        >
                            <Box
                                sx={{
                                    minHeight: 52,
                                    display: "flex",
                                    mt: 10,
                                    alignItems: "flex-start",
                                    justifyContent: "space-between",
                                    flexDirection: {
                                        xs: "column",
                                        md: "row",
                                    },
                                    gap: "6px",
                                }}
                            >
                                {!areStudiesLoading && (
                                    <StudiesFilters
                                        isLoading={areStudiesLoading}
                                        onShowHiddenFiles={handleShowHiddenFiles}
                                        showHiddenFiles={showHiddenFiles}
                                        medias={medias}
                                        selectedStudies={selectedStudies}
                                        onSelect={setSelectedStudies}
                                    />
                                )}
                                <Box style={{ display: "flex", alignItems: "center", height: 28 }}></Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column-reverse",
                                        justifyContent: "start",
                                        alignItems: "self-end",
                                        flex: {
                                            xs: 0,
                                            md: "0 0 280px",
                                        },
                                    }}
                                >
                                    {selectedStudies.size > 0 ? (
                                        <>
                                            <Box
                                                sx={{
                                                    mr: 8,
                                                    gap: 4,
                                                    position: "relative",
                                                    top: "8px",
                                                    display: "flex",
                                                }}
                                            >
                                                <DisplayText
                                                    type="bodySmall"
                                                    variant="semiBold"
                                                    style={{ lineHeight: "10px" }}
                                                    text={selectedStudies.size.toString()}
                                                />
                                                <DisplayText
                                                    type="bodySmall"
                                                    style={{ lineHeight: "10px" }}
                                                    variant="regular"
                                                    text={"Studies selected"}
                                                />
                                            </Box>
                                            <SplitButton
                                                options={[
                                                    {
                                                        label: "View Selected",
                                                        onClick: handleViewAll,
                                                        disabled: !selectAllAvailable,
                                                    },
                                                    ...(userHasRole("PROJECT_MANAGER")
                                                        ? [
                                                              {
                                                                  label: "Move items",
                                                                  onClick: handleOpenMoveItems,
                                                                  disabled: !selectAllAvailable,
                                                              },
                                                          ]
                                                        : []),
                                                    ...(hasPermissions(["Delete"])
                                                        ? [
                                                              {
                                                                  label: "Edit Modalities",
                                                                  onClick: handleOpenEditModality,
                                                                  disabled: !selectAllAvailable,
                                                              },

                                                              {
                                                                  label: "Remove selected",
                                                                  onClick: handleBulkDelete,
                                                              },
                                                          ]
                                                        : []),
                                                ]}
                                            />
                                        </>
                                    ) : null}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    overflow: "auto",
                                    mt: 12,
                                    flex: 1,
                                    ...(encounters?.length === 0 && {
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }),
                                }}
                            >
                                {areStudiesLoading ? (
                                    <LoadingComponent />
                                ) : encounters.length === 0 ? (
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <DisplayText type="bodyMedium" text="No studies found" variant="regular" />
                                    </Box>
                                ) : (
                                    encounters.map((encounter: any, index: any) => {
                                        const isSelected = selectedVisit?.visitId === encounter.visitId;
                                        // Open the first visit if no visit is selected
                                        const isOpen = isSelected || (!selectedVisit && index === 0);

                                        return (
                                            <Box sx={{ mt: 16 }} key={encounter.uuid}>
                                                <VisitAccordion
                                                    onSelect={handleSelect}
                                                    key={encounter.uuid}
                                                    isSelected={isSelected}
                                                    isDefaultOpen={isOpen}
                                                    visit={encounter}
                                                    title={`${encounter.visitName}`}
                                                    selectedStudies={selectedStudies}
                                                />
                                            </Box>
                                        );
                                    })
                                )}
                            </Box>
                        </TabPanel>
                        {crfNotAllowed ? null : (
                            <TabPanel value={tabValue} index={1} style={{ overflow: "auto", height: "100%" }}>
                                {crfErrorHappened ? (
                                    <Box
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        Something went wrong
                                    </Box>
                                ) : crfLoading ? (
                                    <LoadingComponent />
                                ) : (
                                    <CRFTab visits={crfVisits} />
                                )}
                            </TabPanel>
                        )}
                    </>
                )}
            </Box>
            {editModalityOpen && (
                <EditModality
                    mediaIds={Array.from(selectedStudies)}
                    multiple={true}
                    selectStudies={handleSelectStudies}
                    isOpen={editModalityOpen}
                    onClose={handleCloseEditModality}
                    patientId={currentPatient!.uuid}
                />
            )}
            {moveItemsModalOpen && (
                <MoveItemsModal
                    isOpen={moveItemsModalOpen}
                    onClose={handleCloseMoveItems}
                    selectedItems={selectedStudies}
                    onAccept={handleMoveItems}
                    isLoading={isMoving}
                />
            )}
        </Paper>
    );
};

export default React.memo(Studies);
