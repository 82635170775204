import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/system";
import DisplayText from "../../components/DisplayText/DisplayText";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, TextFieldProps } from "@mui/material";
import { useSnackbar } from "notistack";
import useRoles from "../permissions/useRoles";
import { IStudy } from "./studiesSlice";
import { longPrettyDate, prettyDate, shortPrettyDateInUTC } from "../../utils/Dates";
import { useEditStudyDateMutation } from "./dataAccess";
import { DesktopDatePicker } from "@mui/x-date-pickers";

interface IEditableStudyDateProps {
    study: IStudy;
}

const EditableStudyDate: React.FC<IEditableStudyDateProps> = ({ study }) => {
    const initialDate = shortPrettyDateInUTC(new Date(study.createdOn).toString());
    const [showEdit, setShowEdit] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [studyDate, setStudyDate] = useState(initialDate);
    const { userHasRole } = useRoles();

    const [editStudyDate] = useEditStudyDateMutation();

    const handleApply = async () => {
        try {
            handleClose();

            await editStudyDate({
                patientId: study.patientId,
                newDate: longPrettyDate(new Date(studyDate)),
                studyId: study.uuid,
            }).unwrap();

            handleClose();
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
        }
    };

    const handleMouseEnter = () => {
        setShowEdit(true);
    };

    const handleMouseLeave = () => {
        setShowEdit(false);
    };

    const handleClickOpen = () => {
        setShowEdit(false);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setStudyDate(initialDate);
    };

    const handleOnchangeDate = (newDate: Date | null) => {
        if (newDate) {
            setStudyDate(shortPrettyDateInUTC(newDate.toString()));
        }
    };

    const isApplyDisabled = studyDate === initialDate;

    if (!userHasRole("PROJECT_MANAGER")) {
        return (
            <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
                <DisplayText
                    type="bodySmall"
                    variant="semiBold"
                    style={{ marginTop: 16 }}
                    text={`Study from ${prettyDate(new Date(study.createdOn).toString())}`}
                />
            </Box>
        );
    }

    return (
        <Box
            sx={{ cursor: "pointer" }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            display="flex"
            alignItems="center"
        >
            <Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
                    <DisplayText
                        type="bodySmall"
                        variant="semiBold"
                        style={{ marginTop: 16 }}
                        text={`Study from ${prettyDate(new Date(study.createdOn).toString())}`}
                    />
                    {showEdit && (
                        <EditIcon
                            style={{ marginLeft: 8, marginTop: 16, cursor: "pointer", fontSize: "inherit" }}
                            onClick={handleClickOpen}
                        />
                    )}
                </Box>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Study Date</DialogTitle>
                <DialogContent>
                    <DesktopDatePicker
                        value={studyDate}
                        onChange={(newDate: Date | null) => handleOnchangeDate(newDate)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                autoFocus
                                inputProps={{
                                    ...params.inputProps,
                                    readOnly: true,
                                }}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button disabled={isApplyDisabled} onClick={handleApply} variant="contained">
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default EditableStudyDate;
