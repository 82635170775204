import { Build, ExpandLess, ExpandMore, Face, Favorite, Notifications, Publish } from "@mui/icons-material";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import DescriptionIcon from "@mui/icons-material/Description";
import GroupIcon from "@mui/icons-material/Group";
import IosShareIcon from "@mui/icons-material/IosShare";
import ListIcon from "@mui/icons-material/List";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Collapse, ListItemButton, ListSubheader } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import LogoImg from "../../assets/SquareLogo.png";
import ComingSoonFeature from "../../components/ComingSoonFeature/ComingSoonFeature";
import DisplayText from "../../components/DisplayText/DisplayText";
import { nebulaClient2 } from "../../lib/nebulaClient";
import { getSelectedTrial } from "../auth/login/loginSlice";
import usePermissions from "../permissions/usePermissions";
import useRoles from "../permissions/useRoles";

interface IMenuProps {
    onClose: (e: React.MouseEvent) => void;
    onExportClick: () => void;
}
const Menu: React.FC<IMenuProps> = ({ onClose, onExportClick: handleExportCustomFormsData }) => {
    const { hasPermissions } = usePermissions();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { userHasRole } = useRoles();

    const selectedTrial = useAppSelector(getSelectedTrial);
    const hasManual = useAppSelector((state) => state.userInfo.trialHasManual);
    const [openExportCRF, setOpenExportCRF] = useState(false);

    const handleCollapseExportMenu = (e: React.MouseEvent) => {
        e.stopPropagation();
        setOpenExportCRF(!openExportCRF);
    };

    const downloadFileBlob = (blob: Blob, fileName: string) => {
        const url = window.URL.createObjectURL(blob);

        // Crete link element
        const link = document.createElement("a");
        link.href = url;

        // get trial name from redux store
        link.setAttribute("download", fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link!.parentNode!.removeChild(link);
    };

    const handleExportCensus = async () => {
        if (!selectedTrial) return;

        enqueueSnackbar("Generating Census report...", { variant: "info" });

        try {
            const fileData = await nebulaClient2.get(`report/${selectedTrial.uuid}/census?filterColumns=fileName`);

            const fileName = `${selectedTrial.name}_Census_${new Date().toISOString()}.csv`;

            // @ts-ignore
            downloadFileBlob(new Blob([fileData.data]), fileName);

            closeSnackbar();
        } catch (e) {
            closeSnackbar();
            enqueueSnackbar("Something went wrong", { variant: "error" });
        }
    };

    const handleExportCrf = async (e: React.MouseEvent, final: boolean = false) => {
        e.stopPropagation();
        if (!selectedTrial) return;

        enqueueSnackbar("Generating CRF report...", { variant: "info" });

        try {
            const fileData = await nebulaClient2.get(`trial/${selectedTrial.uuid}/crf?final=${final}`);

            const fileName = `${selectedTrial.name}_${final ? "Final" : "All"}_CRF_${new Date().toISOString()}.csv`;

            // @ts-ignore
            downloadFileBlob(new Blob([fileData.data]), fileName);

            closeSnackbar();
        } catch (e) {
            closeSnackbar();
            enqueueSnackbar("Something went wrong", { variant: "error" });
        }
    };

    const handleExportCertificationData = async () => {
        if (!selectedTrial) return;

        enqueueSnackbar("Generating Certification report...", { variant: "info" });

        try {
            const fileData = await nebulaClient2.get(`trial/${selectedTrial.uuid}/crf?certification=true`);

            const fileName = `${selectedTrial.name}_Certification_${new Date().toISOString()}.csv`;

            // @ts-ignore
            downloadFileBlob(new Blob([fileData.data]), fileName);

            closeSnackbar();
        } catch (e) {
            closeSnackbar();
            enqueueSnackbar("Something went wrong", { variant: "error" });
        }
    };

    const handleDownloadManual = async () => {
        if (!selectedTrial) return;

        enqueueSnackbar("Downloading manual...", { variant: "info" });

        try {
            const fileData = await nebulaClient2.get(`trial/${selectedTrial.uuid}/manual`, {
                responseType: "arraybuffer",
            });

            const fileName = `manual_${selectedTrial.name}.pdf`;
            // @ts-ignore
            const blob = new Blob([fileData.data], { type: "application/pdf" });

            downloadFileBlob(blob, fileName);

            closeSnackbar();
        } catch (e) {
            closeSnackbar();
            enqueueSnackbar("Something went wrong", { variant: "error" });
        }
    };

    return (
        <Box
            sx={{
                width: 300,
                height: "100%",
                backgroundColor: "#2f2f2f",
                display: "flex",
                flexDirection: "column",
            }}
            role="presentation"
        >
            <List
                sx={{ pb: 0 }}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <Box sx={{ my: 20, display: "flex" }}>
                                <img style={{ maxHeight: 50 }} src={LogoImg} alt="Voxeleron Logo" />
                            </Box>

                            <Box>
                                <DisplayText
                                    type="h5"
                                    variant="regular"
                                    text="iNebula"
                                    style={{ marginBottom: "0px" }}
                                />
                                <DisplayText
                                    type="bodyXSmall"
                                    variant="regular"
                                    text="by Voxeleron"
                                    style={{ marginBottom: "0px" }}
                                />
                            </Box>
                        </Box>
                    </ListSubheader>
                }
            />
            <Divider />
            <List onClick={onClose}>
                <Link
                    to={{
                        pathname: "/patients",
                    }}
                >
                    <ListItem button key={"Review Patients"}>
                        <ListItemIcon>
                            <Face style={{ color: "#fe8c03" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Review Patients"} />
                    </ListItem>
                </Link>
                {hasPermissions(["Upload"]) && (
                    <Link
                        to={{
                            pathname: "/upload",
                        }}
                    >
                        <ListItem button key={"Upload Images"}>
                            <ListItemIcon>
                                <Publish style={{ color: "#fe8c03" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Upload Images"} />
                        </ListItem>
                    </Link>
                )}
                <ComingSoonFeature>
                    <ListItem disabled button key={"Check Notifications"}>
                        <ListItemIcon>
                            <Notifications style={{ color: "#fe8c03" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Check Notifications"} />
                    </ListItem>
                </ComingSoonFeature>
                <ComingSoonFeature>
                    <ListItem disabled button key={"Manage System"}>
                        <ListItemIcon>
                            <Build style={{ color: "#fe8c03" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Manage System"} />
                    </ListItem>
                </ComingSoonFeature>
                <ComingSoonFeature>
                    <ListItem disabled button key={"Edit configration and profile"}>
                        <ListItemIcon>
                            <Favorite style={{ color: "#fe8c03" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Edit configration and profile"} />
                    </ListItem>
                </ComingSoonFeature>
                {userHasRole("PROJECT_MANAGER") && (
                    <>
                        <ListItemButton onClick={handleCollapseExportMenu}>
                            <ListItemIcon>
                                <IosShareIcon style={{ color: "#fe8c03" }} />
                            </ListItemIcon>
                            <ListItemText primary="Export Data" />
                            {openExportCRF ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openExportCRF} timeout="auto" unmountOnExit>
                            <List component="div" sx={{ pl: 32 }}>
                                <ListItem button onClick={handleExportCrf}>
                                    <ListItemIcon>
                                        <ListIcon style={{ color: "#fe8c03" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Full CRF Data" />
                                </ListItem>
                                <ListItem button onClick={(e) => handleExportCrf(e, true)}>
                                    <ListItemIcon>
                                        <PlaylistAddCheckIcon style={{ color: "#fe8c03" }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Final CRF Data" />
                                </ListItem>
                                <ListItem
                                    disabled={!selectedTrial}
                                    button
                                    onClick={handleExportCertificationData}
                                    key={"Certification Data"}
                                >
                                    <ListItemIcon>
                                        <PhotoCameraIcon style={{ color: "#fe8c03" }} />
                                    </ListItemIcon>
                                    <ListItemText primary={"Certification Data"} />
                                </ListItem>
                                <ListItem
                                    button
                                    disabled={!selectedTrial}
                                    onClick={handleExportCensus}
                                    key={"Export Census"}
                                >
                                    <ListItemIcon>
                                        <GroupIcon style={{ color: "#fe8c03" }} />
                                    </ListItemIcon>
                                    <ListItemText primary={"Census"} />
                                </ListItem>
                                <ListItem
                                    disabled={!selectedTrial}
                                    button
                                    onClick={handleExportCustomFormsData}
                                    key={"Custom Form Data"}
                                >
                                    <ListItemIcon>
                                        <DescriptionIcon style={{ color: "#fe8c03" }} />
                                    </ListItemIcon>
                                    <ListItemText primary={"Custom Form Data"} />
                                </ListItem>
                            </List>
                        </Collapse>
                    </>
                )}

                {userHasRole("PROJECT_MANAGER") && (
                    <Link
                        to={{
                            pathname: "/dashboard",
                        }}
                    >
                        <ListItem button key={"Dashboard"}>
                            <ListItemIcon>
                                <SsidChartIcon style={{ color: "#fe8c03" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Dashboard"} />
                        </ListItem>
                    </Link>
                )}

                {hasManual && (
                    <ListItem button key={"Download Manual"} onClick={handleDownloadManual}>
                        <ListItemIcon>
                            <DescriptionIcon style={{ color: "#fe8c03" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Download Manual"} />
                    </ListItem>
                )}
            </List>

            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "end",
                    p: 5,
                }}
            >
                <DisplayText type="bodyXSmall" text="v1.13.0" />
            </Box>
        </Box>
    );
};

export default Menu;
