import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Button, Grid } from "@mui/material";
import CompareMediaViewer from "./CompareMediaViewer";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { IMedia } from "../../studies/studiesSlice";
import { Divider } from "@mui/material";
import { getMimeTypePrettyName } from "../../../utils/MediaUtils";

interface IAddStudyDialogProps {
    onClose: () => void;
    open: boolean;
    selectedMediaViewerIds: string[];
    compareId: string;
    idsToCompare: IMedia[];
}

const CompareToolDialog: React.FC<IAddStudyDialogProps> = ({
    onClose,
    open,
    selectedMediaViewerIds,
    compareId,
    idsToCompare,
}) => {
    const [selectedId, setSelectedId] = React.useState("");
    const handleClose = () => {
        setSelectedId("");
        onClose();
    };
    const handleListItemClick = (value: string) => {
        setSelectedId(selectedMediaViewerIds.find((id) => id.split(":")[1] === value) || "");
    };

    const renderStudy = (media: IMedia) => (
        <ListItem sx={{ pl: 20 }} button onClick={() => handleListItemClick(media.uuid)} key={media.uuid}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                    style={{ width: 50, height: 50, borderRadius: 5, marginRight: 15 }}
                    src={media.mediaUrl}
                    alt={media.mimeType}
                />
                <ListItemText
                    primary={`${getMimeTypePrettyName(media.mimeType)} ${
                        media.laterality ? `- ${media.laterality}` : ""
                    } ${media.fileName}`}
                />
            </Box>
        </ListItem>
    );

    const renderVisit = (media: IMedia) => (
        <Box key={media.uuid}>
            <Divider />
            <li style={{ paddingLeft: 25 }} key={media.uuid}>
                <ul>{renderStudy(media)}</ul>
            </li>
        </Box>
    );

    return (
        <>
            {!selectedId.length ? (
                <Dialog fullWidth maxWidth="sm" scroll="paper" onClose={handleClose} open={open}>
                    <DialogTitle>Compare with</DialogTitle>
                    <List subheader={<li />}>{idsToCompare.map((media) => renderVisit(media))}</List>
                </Dialog>
            ) : (
                <Dialog fullScreen onClose={handleClose} open={open}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <DialogTitle>Compare Tool</DialogTitle>
                        <Button onClick={handleClose} color="primary">
                            <CloseIcon />
                        </Button>
                    </Box>
                    <Box sx={{ margin: 10 }}>
                        <Grid container spacing={5}>
                            <Grid
                                sx={{
                                    my: 8,
                                    transition: "all 0.3s ease-in-out",
                                    marginRight: 0,
                                    paddingRight: 0,
                                }}
                                item
                                xl={6}
                                xs={6}
                                key={compareId}
                            >
                                <CompareMediaViewer mediaViewerId={compareId} sideLabel="Left" />
                            </Grid>
                            <Grid
                                sx={{
                                    my: 8,
                                    transition: "all 0.3s ease-in-out",
                                    marginLeft: 0,
                                    paddingLeft: 0,
                                }}
                                item
                                xl={6}
                                xs={6}
                                key={selectedId}
                            >
                                <CompareMediaViewer mediaViewerId={selectedId} sideLabel="Right" />
                            </Grid>
                        </Grid>
                    </Box>
                </Dialog>
            )}
        </>
    );
};

export default CompareToolDialog;
