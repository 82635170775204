import { useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Box, Breadcrumbs, CircularProgress, Fab, Grid, Link, Typography } from "@mui/material";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import MediaViewer from "./MediaViewer";
import VisitHeader from "./VisitHeader";
import AddStudyDialog from "./AddStudyDialog";
import { IPatient } from "../patients/patientsSlice";
import { IEncounter, IMedia } from "../studies/studiesSlice";
import { useGetPatientStudiesByEncounterQuery, useGetPatientVisitsQuery } from "../studies/dataAccess";
import { useGetPatientByIdQuery } from "../patients/dataAccess";
import { getMediaViewerIdsFromUri, getQueryParamFromStudyId } from "./utils";
import * as queryString from "query-string";
import CRFDrawer from "./CRFDrawer";
import { getCommaSeparatedPatientName } from "../patients/utils";
import CompareToolDialog from "./compareTool/CompareTool";
import { selectMediaById } from "./viewerSlice";

const VisitsAnalyzer = () => {
    const { patientId } = useParams<{ patientId: string }>();
    const location = useLocation();
    const history = useHistory();
    const [addStudyDialogOpen, setAddStudyDialogOpen] = useState(false);
    const [compareToolDialogOpen, setCompareToolDialogOpen] = useState(false);
    const [maximizedViewerId, setMaximizedViewerId] = useState<string | null>(null);
    const [idsToCompare, setIdsToCompare] = useState<IMedia[]>([]);
    const [compareID, setCompareID] = useState("");

    const selectedMediaViewerIds = getMediaViewerIdsFromUri(location.search) as string[];
    const [selectedEncounter, setSelectedEncounter] = useState<IEncounter>();

    const { isLoading: studyLoading, data: studiesByEncounter } = useGetPatientStudiesByEncounterQuery({ patientId });

    const { data: patientData } = useGetPatientByIdQuery(patientId);

    const patient = patientData;

    const handleOpenAddStudyDialog = () => {
        setAddStudyDialogOpen(true);
    };

    const handleCloseAddStudyDialog = () => {
        setAddStudyDialogOpen(false);
    };

    const handleCloseCompareToolDialog = () => {
        setCompareToolDialogOpen(false);
        setIdsToCompare([]);
        setCompareID("");
    };

    const handleAddStudy = (newStudyId: string) => {
        const studiesIds = getMediaViewerIdsFromUri(location.search) as string[];

        const studyIdAsQueryParam = getQueryParamFromStudyId(newStudyId);

        history.replace({
            pathname: location.pathname,
            search: queryString.stringify({ st: [...studiesIds, studyIdAsQueryParam] }),
        });
        handleCloseAddStudyDialog();
    };

    const handleCloseDrawer = () => setSelectedEncounter(undefined);

    const handleOpenDrawer = (selectedEncounter: IEncounter) => {
        setSelectedEncounter(selectedEncounter);
    };

    const handleToggleMaximize = (viewerId: string) => {
        setMaximizedViewerId(maximizedViewerId === viewerId ? null : viewerId);
    };
    const handleOnCompareClick = (selectedMediaId: string) => {
        const unselectedMedias = selectedMediaViewerIds.filter((mediaId) => mediaId !== selectedMediaId);
        const medias = unselectedMedias.map((selectedMediaId) =>
            selectMediaById(studiesByEncounter, selectedMediaId.split(":")[1])
        );
        if (medias.every((media) => media !== undefined)) {
            //@ts-ignore
            setIdsToCompare([...idsToCompare, ...medias]);
            setCompareID(selectedMediaId);
            setCompareToolDialogOpen(true);
        }
    };

    if (studyLoading || !patient)
        return (
            <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
            </Box>
        );

    return (
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <VisitHeader patient={patient as IPatient} />
            <Box
                sx={{
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    overflow: "auto",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 8 }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/patients">
                            Patients
                        </Link>
                        <Typography color="text.primary">
                            {getCommaSeparatedPatientName(patient!.firstName, patient!.lastName)}
                        </Typography>
                    </Breadcrumbs>
                    <Box>
                        <Fab
                            sx={{ backgroundColor: "white" }}
                            variant="extended"
                            size="small"
                            color="primary"
                            aria-label="add"
                            onClick={handleOpenAddStudyDialog}
                        >
                            <AddCircleIcon sx={{ mr: 8 }} />
                            Add Study
                        </Fab>
                    </Box>
                </Box>
                {selectedMediaViewerIds.length === 0 ? (
                    <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                        Select a study
                    </Box>
                ) : (
                    <Grid container spacing={16}>
                        {selectedMediaViewerIds.map((mediaViewerId: string) => {
                            const key = mediaViewerId.split(":")[0];
                            const isMaximized = maximizedViewerId === mediaViewerId;

                            return (
                                <Grid
                                    sx={{
                                        my: 8,
                                        transition: "all 0.3s ease-in-out",
                                    }}
                                    item
                                    xl={isMaximized ? 12 : 6}
                                    xs={12}
                                    key={key}
                                >
                                    <MediaViewer
                                        onOpenCRF={handleOpenDrawer}
                                        mediaViewerId={mediaViewerId}
                                        onCompare={handleOnCompareClick}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </Box>
            <CRFDrawer
                selectedEncounterId={selectedEncounter?.visitId}
                isOpen={Boolean(selectedEncounter)}
                title={`CRF for: ${selectedEncounter?.visitName}`}
                onClose={handleCloseDrawer}
                patient={patientData}
            />
            <AddStudyDialog
                onClose={handleCloseAddStudyDialog}
                onAddStudy={handleAddStudy}
                open={addStudyDialogOpen}
                encounters={studiesByEncounter}
            />
            <CompareToolDialog
                onClose={handleCloseCompareToolDialog}
                open={compareToolDialogOpen}
                selectedMediaViewerIds={selectedMediaViewerIds}
                compareId={compareID}
                idsToCompare={idsToCompare}
            />
        </Box>
    );
};

export default VisitsAnalyzer;
